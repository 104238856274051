// http://www.binvisions.com/articles/tablet-smartphone-resolutions-screen-size-list/
const Screens = {
  xxs: '320px',
  xs: '480px',
  sm: '768px',
  md: '900px',
  lg: '1200px',
  xl: '1800px'
};

// export default Screens;
module.exports = Screens;
