import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Line = styled.hr`
  ${tw`
    overflow-visible
    relative
    mb-0
  `};
  color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  ${({width, cropBottom}) => {
    return [
      width && tw`ml-0`,
      cropBottom ? tw`
        pb-1px
        sm:pb-1px
        md:pb-1px
        lg:pb-1px
        xl:pb-1px
        mt-1
        lg:mt-1.5
        xl:mt-2
      ` : tw`
        py-1
        lg:py-1.5
        xl:py-2
      `
    ];
  }};
  &: after {
    content: " ";
    ${tw`absolute w-full block`};
    height: 2px;
    background-size: 0.6rem 1rem;
    background-position-x: -1rem;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.5) 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(-90deg, rgba(0, 0, 0, 0.5) 4px, transparent 1px);
    ${({ color, width, cropBottom }) => {
      return [
        cropBottom ? tw`bottom-0` : `top: 50%;`,
        width && (`
          width: ${width};
        `),
        color === `white` && (`
          background-image: radial-gradient(circle, rgba(255, 255, 255, 0.5) 1px, rgba(0, 0, 0, 0) 1px),
          linear-gradient(-90deg, rgba(255, 255, 255, 0.5) 4px, transparent 1px);
        `)
      ];
    }
  }};
`;

export default class Component extends React.Component {
  render () {
    const { props } = this;
    return (
      <Line {...props} />
    );
  }
}
