import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import logger from 'utils/logger';
import {
  SectionLabel
} from 'components/Text';

const Hero = styled.div`
  ${tw`
    flex-2
    mx-auto
    text-center
    w-full
    lg:w-7/12
  `};
`;

const Title = styled.h1`
  ${tw`
    text-gray-700
    text-hero
    tracking-tight
    font-semibold
    leading-tighter
  `};
`;

const Content = styled.div`
  ${tw`
    text-gray-600
    text-xl
    lg:text-3xl
    leading-tight
  `};
  > p {
    ${tw`
      text-gray-600
      text-xl
      md:text-2xl
      lg:text-3xl
      leading-tight
    `};
  }
`;

export default class Component extends React.Component {
  render() {
    const {
      title,
      content,
      label
    } = this.props;

    return (
      <Hero>
        {label && (<SectionLabel label={label} />)}
        {title && (
          <Title>
            {title}
          </Title>
        )}
        {content && (
          content.childMdx && (
            <Content>
              <MDXRenderer>
                {content.childMdx.body}
              </MDXRenderer>
            </Content>
          )
        )}
      </Hero>
    );
  }
}
Component.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string
};
Component.defaultProps = {
  content: ``,
  label: ``,
  title: ``
};
