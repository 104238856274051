import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

import {
  LineSeparator
} from 'components/Elements';

const Label = styled.div`
  ${tw`
    font-sans
    block
    leading-normal
    tracking-wide
    font-bold
    text-sm
    md:text-sm
    lg:text-md
    xl:text-lg
    relative
  `};
  span {
    ${tw`
      absolute
      left--1/24
      md:left--3.5
      xl:left--4.5
      w-1/24
      text-center
      font-light
      tracking-wide
      ml-6px
      text-lg
      top--2px
      lg:top-2px
    `};
  }
  ${({ ordinal, color }) => {
    return [
      ordinal && tw``,
      color === `white` && tw`text-white`,
      color === `black` && tw`text-black`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const { label, ordinal } = this.props;
    return (
      <Label {...this.props} >
        {ordinal && (
          <span>
            {ordinal}
            .
          </span>
        )}
        {label}
        <LineSeparator {...this.props} />
      </Label>
    );
  }
}

Component.propTypes = {
  label: PropTypes.string,
  ordinal: PropTypes.string
};
Component.defaultProps = {
  label: `Label`,
  ordinal: ``
};
