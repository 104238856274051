import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const Text = styled.h2`
  ${tw`
    text-h4
    leading-h4
    xxs:text-h2
    xxs:leading-h2
    xs:text-hero
    xs:leading-hero
    sm:text-hero-lg
    sm:leading-hero-lg
    lg:text-hero-xl
    lg:leading-hero-xl
    font-light
    tracking-tight
    relative
  `};
  left: -2px;
  & mark {
    ${tw`
      relative
      bg-white
      uppercase
      tracking-wide
      inline-block
      font-normal
      m-0
      px-0.5
      xxs:px-0.75
      xs:px-1
      xxs:h-3
      sm:h-4
      xxs:pt-0.5
      sm:pt-1
    `};
    top: .125rem
  }
  & strong {
    ${tw`
      uppercase
      tracking-tighter
    `};
  }
  & i {
    ${tw`
      font-serif
    `};
  }
`

export default class Component extends React.Component {
  render () {
    return (
      <Text>{this.props.children}</Text>
    )
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
