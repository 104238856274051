import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const Text = styled.h1`
  ${tw`
    font-sans
    font-light
    tracking-wider
    text-hero
    leading-hero
    md:text-hero-xl
    md:leading-hero-xl
    xl:text-superhero-lg
    xl:leading-superhero-lg
  `};
  margin-bottom: 3rem;
`;

export default class Component extends React.Component {
  render () {
    const { children } = this.props;
    return (
      <Text>
        {children}
      </Text>
    );
  }
}

Component.propTypes = {
  children: PropTypes.node
};

Component.defaultProps = {
  children: ``
};
