import { navigate } from 'gatsby';

export const navigateRedux = (to) => {
  // const message = `You've just added ${person.name} to the Most Wanted List.`;
  setTimeout(() => {
    navigate(to);
  }, 800);
  // return (dispatch) => {
  //   dispatch({ type: `CLOSE_MENU` })
  //   // dispatch(newToast(message))
  // };
};

// export const navigateReduxAsync = (value) => {
//   console.log(`IT WORKS! navigateReduxAsync`, value);
//   return {
//     type: `CLOSE_MENU`,
//     value: false
//   };
// };
function navigateReduxAsync (value) {
  return {
    type: `CLOSE_MENU`,
    value: value
  };
}