import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import {
  SectionLabel
} from 'components/Text';

const TitleMDX = styled.span``;
const Section = styled.section`
  ${tw`
    bg-black
    text-white
    py-4
    lg:pt-8
    xl:pt-10
    px-3
    min-h-screen
    md:px-1/12
    lg:px-1/14
    xl:px-1/48
  `};
`;
const Content = styled.div`
  ${tw`
    xl:mx-1/12
    pb-1
    md:pb-2
    lg:pb-3
    w-full
  `};
`;
const SectionTitle = styled.h1`
  ${tw`
    pt-1
    mb-2
    md:mb-3
    relative
    tracking-tight
    font-thin
    text-hero
    lg:text-superhero-xl
    lg:leading-0.8
    xl:max-w-2/3
    text-gray-200
  `};
  b {
    ${tw`
      text-white
      font-bold
    `};
  }
`;
const SectionSubtitle = styled.div`
  ${tw`
    md:font-light
    tracking-wide
    text-sm
    md:text-md
    lg:text-lg
    lg:text-xl
    pb-1
    md:pb-2
    lg:pb-3
  `};
  p {
    ${tw`
      leading-1.5
      md:leading-1.6
      mb-0
    `};
  }
`;
const Contact = styled.div`
  ${tw`
    flex-2
    md:mb-2
    md:w-1/2
    lg:w-1/4
    leading-2.4
    text-gray-200
    tracking-wide
    lg:text-xl
    font-light
  `};
  address {
    ${tw`
      not-italic
      mb-0
      md:mb-2
    `};
    div {
      ${tw`
        not-italic
        block
        pt-0.5
        mb-0.5
        text-sm
        lg:text-md
        tracking-wider
      `};
      span {
        ${tw`
          pb-0.25
          block
        `};
      };
      a, span {
        ${tw`
          text-gray-200
          leading-1.7
        `};
      };
    }
  }
  h1 {
    ${tw`
      text-white
      font-semibold
      text-lg
      lg:text-xl
      xl:text-2xl
      mb-1
      pt-2
    `};
  };
  a, span {
    ${tw`
      leading-2.15
      pb-0.5
    `};
  };
  a {
    ${tw`
      text-gray-200
      hover:text-white
      transition-all
      transition-400
      transition-ease-in-out
      relative
    `};
    &:after{
      ${tw`
        absolute
        block
        w-0
        bg-red
        h-2px
        transition-all
        transition-700
        transition-ease-in-out
        left-0
        bottom-1px
      `};
      content: '';
      background-size: 0.6rem 1rem;
      background-position-x: -1rem;
      background-image: radial-gradient(circle, #FF80A0 1px, rgba(255, 255, 255, 0) 1px),
      linear-gradient(-90deg, #FF80A0 4px, transparent 1px);
    };
    &:hover {
      :after{
        ${tw`
          w-full
          transition-400
        `};
      };
    };
  };
`;
const wavesMotionTwo = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;
const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right--2px
    w-full
    top--4px
    lg:top--0.5
    overflow-hidden
    h-2
    lg:h-3
  `};
  transform: rotate(180deg);
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  svg {
    ${tw`
      min-h-12px
      max-h-24px
      lg:min-h-20px
      lg:max-h-40px
      relative
      w-full
    `};
    margin-bottom:-7px; /*Fix for safari gap*/
    g {
      > use {
        animation: ${wavesMotionTwo} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        ${tw`
          opacity-50
          fill-gold-300
        `};
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        ${tw`
          opacity-40
          fill-gold-300
        `};
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        ${tw`
          opacity-20
          fill-gold-300
        `};
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        ${tw`
          opacity-100
          fill-gold-300
        `};
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

export default class Component extends React.Component {
  render () {
    const {
      hero,
      contacts,
      ordinal,
      prettyTitle,
      content
    } = this.props;
    const {
      label
    } = hero;
    const persons = [];
    const addresses = [];
    const emails = [];

    contacts.forEach((edge) => {
      const { node } = edge;
      // Persons
      if (node.name) {
        persons.push(node);
      }
      // Addresses
      else if (node.address1) {
        addresses.push(node);
      }
      // Emails
      else {
        emails.push(node);
      }
    });

    return (
      <Section id={`contact`}>
        <Container fluid>
          <Row>
            <Content>
              <Fade top distance={`2em`}>
                <SectionLabel label={`We're here for you`} ordinal={ordinal} color={`white`} width={`180px`} />
              </Fade>
              <Fade bottom distance={`2em`}>
                {prettyTitle && (
                  prettyTitle.childMdx && (
                    <SectionTitle>
                      <MDXProvider
                        components={{
                          p: TitleMDX
                        }}
                      >
                        <MDXRenderer>
                          {prettyTitle.childMdx.body}
                        </MDXRenderer>
                      </MDXProvider>
                    </SectionTitle>
                  )
                )}
              </Fade>
              <Fade bottom distance={`2em`}>
                {content && (
                  content.childMdx && (
                    <SectionSubtitle>
                      <MDXProvider
                        components={{
                        }}
                      >
                        <MDXRenderer>
                          {content.childMdx.body}
                        </MDXRenderer>
                      </MDXProvider>
                    </SectionSubtitle>
                  )
                )}
              </Fade>
              <Row>
                <Contact>
                  <Fade bottom distance={`1em`} delay={0}>
                  <address>
                    <h1>Business Inquires</h1>
                    <span>Lau Jimenez</span>
                    <br />
                    <a href={`tel:+14243190892`} target={`_blank`}>+1 424 319 0892</a>
                    <br />
                    <a href={`mailto:l.jimenez@pawa.rocks`} target={`_blank`}>l.jimenez@pawa.rocks</a>
                  </address>
                  </Fade>
                </Contact>
                <Contact>
                  <Fade bottom distance={`1em`} delay={150}>
                  <h1>General Inquires</h1>
                  <a href={`mailto:hello@pawa.rocks`} target={`_blank`}>hello@pawa.rocks</a>
                  <h1>Jobs</h1>
                  <a href={`mailto:jobs@pawa.rocks`} target={`_blank`}>jobs@pawa.rocks</a>
                  </Fade>
                </Contact>
                <Contact>
                  <Fade bottom distance={`1em`} delay={300}>
                  <h1>Bogotá</h1>
                  <address>
                    <div>
                      <span>K67 #108-38, I6-501.</span>
                      <span>111121 Bogotá. Colombia.</span>
                    </div>
                    <div>
                      <a href={`tel:+5712531501`} target={`_blank`}>+57 1 253 1501</a>
                      <br />
                      <a href={`mailto:bog@pawa.rocks`} target={`_blank`}>bog@pawa.rocks</a>
                    </div>
                  </address>
                  {/* 
                  <h1>Los Angeles</h1>
                  <address>
                    <div>
                      <span>Burbank CA. USA</span>
                    </div>
                    <div>
                      <a href={`tel:+14243190893`} target={`_blank`}>+1 424 319 0893</a>
                      <br />
                      <a href={`mailto:la@pawa.rocks`} target={`_blank`}>la@pawa.rocks</a>
                    </div>
                  </address>
                   */}
                  </Fade>
                </Contact>
                <Contact>
                  <Fade bottom distance={`1em`} delay={450}>
                  <h1>Madrid</h1>
                  <address>
                    <div>
                      <span>Calle Quintana 8, Local 3.</span>
                      <span>Madrid 28008. España.</span>
                    </div>
                    <div>
                      <a href={`tel:+34911967903`} target={`_blank`}>+34 911 967903</a>
                      <br />
                      <a href={`mailto:mad@pawa.rocks`} target={`_blank`}>mad@pawa.rocks</a>
                    </div>
                  </address>
                  </Fade>
                </Contact>
              </Row>
            </Content>
          </Row>
        </Container>
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" />
              <use xlinkHref="#gentle-wave" x="48" y="5" />
              <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
