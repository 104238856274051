import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// import ReactPlayer from 'react-player';
// import logger from 'utils/logger';
import _ from 'lodash';

import { Container, Row } from 'components/GridSystem';

import {
  LineSeparator,
  BgImg
} from 'components/Elements';

import {
  SectionLabel,
  SectionTitle
} from 'components/Text';

const transition = css`
  transition: all 300ms ease;
`;
const Section = styled.section`
  ${tw`
    bg-gray-800
    text-white
    relative
  `};
`;
const Modal = styled.aside`
  ${tw`
    flex
    text-center 
    content-center
    fixed
    h-full
    w-full
    z-10
    cursor-pointer
  `};
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  ${({ open }) => {
    return [
      !open ? tw`invisible` : tw`visible`
    ];
  }};
`;
const ModalContent = styled.aside`
  ${tw`
    py-1
    lg:py-2
    mx-auto
    w-full
    px-2
    lg:px-1/12
  `};
  h3 {
    ${tw`
      mb-1
      font-light
      text-xl
      lg:text-3xl
    `};
  }
`;
const ModalClose = styled.span`
  ${tw`
    cursor-pointer
    bg-gray-800
    hover:bg-gray-700
    absolute
    block
    rounded-sm
    mt-1
    mr-1
    lg:mt-2
    lg:mr-1/48
    transition-all
    transition-200
    transition-ease
  `};
  width: 36px;
  height: 36px;
  top: 0;
  right: 0;
  &:before, &:after{
    ${tw`
      absolute
      bg-gray-600
      transition-all
      transition-200
      transition-ease
      w-4px
      h-8px
      top-1px
    `};
    content:'';
  }
  &:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left: 16px;
  }
  &:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right: 16px;
  }
  :hover {
    &:before, &:after{
      ${tw`
        bg-brand-secondary
      `};
    }
  }
`;

const ColList = styled.div`
  ${tw`
    pt-3
    md:pt-4
    lg:py-5
    xl:py-6
    px-3
    md:px-1/16
    xl:px-1/12
    bg-brand-primary
    lg:w-1/2
    flex-2
    w-full
  `};
`;

const ColImage = styled.div`
  ${tw`
    hidden
    lg:block
    bg-black
    lg:w-1/2
    flex-2
    w-full
    z-10
    relative
    left--1px
    relative
    min-h-1
  `};
  :before {
    ${tw`
      absolute
      top-0
      bottom-0
      left-0
      right-0
    `};
    content: "";
    position: absolute;
    z-index: 1;
    background: repeating-linear-gradient(
      -45deg,
      rgba(0,0,0,0.3),
      rgba(0,0,0,0.1) 2px,
      rgba(0,0,0,0.3) 4px
    );
  }
`;

const ColBgImage = styled.div`
  ${tw`
    absolute
    top-0
    bottom-0
    left-0
    right-0
    transition-all
    transition-ease
    transition-500
    bg-gold
  `};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  ${({ imgSrc, active}) => {
    return [
      active ? tw`opacity-100` : tw`opacity-0`,
      imgSrc && (`background-image: url('${imgSrc}');`)
    ];
  }};
`;

const Filters = styled.ul`
  ${tw`
    pt-1
    lg:pt-2
    mb-0
    text-sm
    font-bold
    uppercase
    md:text-md
  `};
`;
const Filter = styled.li`
  ${tw`
    text-black
    inline-block
    mb-0
    transition-all
    transition-ease
    transition-600
    hover:transition-200
    hover:text-black
    cursor-pointer
  `};
  ${({ active }) => {
    return [
      !active ? tw`text-brand-primary-900 opacity-90` : tw`text-white hover:text-white opacity-100`
    ];
  }};
  &:after {
    ${tw`
      inline-block
      border-0
      border-solid
      border-l-2
      border-brand-primary-600
      h-16px
      mx-0.75
      lg:mx-2
      relative
    `};
    top: 2px;
    content: ' ';
  }
  :last-child:after {
    ${tw`
      hidden
    `};
  }
`;
const Projects = styled.ul`
  ${tw`
    mx--3
    md:mx--4
    lg:mx-0
    pt-1
    lg:pt-2
    pb-0
    md:pb-0
    lg:pb-3
    mt--1
    md:mt--1
    lg:mt-4
    xl:mt-0
  `};
`;
const Project = styled.li`
  ${tw`
    relative
    text-brand-primary-900
    hover:text-white
    mb-0
    pt-1.5
    md:pt-4
    lg:pt-2
    pb-1
    md:pb-6
    lg:pb-2
    xl:pt-2.5
    xl:pb-2.5
    font-heavy
    md:tracking-tight
    lg:tracking-tight
    xl:tracking-tighter
    cursor-pointer
    uppercase
    text-xl
    md:text-hero
    lg:text-superhero
    xl:text-superhero-lg
    transition-all
    transition-ease
    transition-400
    hover:transition-200
    border-solid
    border-0
    border-t
    lg:border-t-0
    border-gray-700
    relative
    z-0
    bg-black
    lg:bg-transparent
  `};
  ${transition};
  ${({ active, showCase }) => {
    return [
      !active ? tw`
          text-gray-300
          lg:text-brand-primary-900
          pl-3
          lg:pl-0
          pb-1
      ` : tw`
          text-white
          pb-8
          md:pb-12
          lg:pb-2
          xl:lg:pb-2.5
          hover:text-white
          pl-3
          md:pl-4
          lg:left-28px
          lg:pl-4px
      `,
      !showCase ? tw`h-1px overflow-hidden opacity-0 py-0 lg:py-0 xl:py-0` : tw`opacity-100`
    ];
  }};
  &:last-child {
    ${({ active, showCase }) => {
      return [
        !active ? tw`pb-2 md:pb-7 lg:pb-2` : tw`pb-8 md:pb-12 lg:pb-2`
      ];
    }};
  }
  &:before, &:after{
    ${({ active }) => {
      return [
        active && tw`
          absolute
          bg-white
          transition-all
          transition-200
          transition-ease
          h-1
          lg:h-2
          lg:h-20px
          w-2px
          left-1.5
          top-2.5
          md:top-5
          lg:left--1/48
          lg:top-5
        `,
      ];
    }};
    content:'';
  }
  &:before,
  &:after {
    ${tw`
      z-10
    `};
  }
  &:before{
    ${tw`
    `};
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
  &:after{
    ${tw`
      mt--10px
      lg:mt--12px
    `};
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
`;
const LinkScreen = styled.a`
  ${tw`
    absolute
    top-0
    left-0
    block
    w-full
    h-full
    z-10
  `};
`;

const ProjectTitle = styled.span`
  ${tw`
    block
    tracking-wide
    lg:tracking-normal
    normal-case
    lg:pl-6px
    text-xs
    md:text-2xl
    font-regular
  `};
`;
const ProjectContent = styled.div`
${tw`
    leading-0.8
    relative
    z-10
  `};
`;
const ProjectImageMobile = styled.div`
  ${tw`
    block
    absolute
    top-0
    left-0
    w-full
    h-full
    z--10
    lg:hidden
  `};
  a {
    ${tw`
      absolute
      left-3
      mb-0
      font-regular
      text-sm
      z-10
      tracking-wider
    `};
    ${transition};
    ${({ active, img }) => {
      return [
        !active ? tw`opacity-0 bottom-0` : tw`opacity-100 bottom-2`
      ];
    }};
  }
  :before {
    ${tw`
      absolute
      top-0
      bottom-0
      left-0
      right-0
    `};
    content: "";
    position: absolute;
    z-index: 1;
    background: repeating-linear-gradient(
      -45deg,
      rgba(0,0,0,0.4),
      rgba(0,0,0,0.3) 2px,
      rgba(0,0,0,0.4) 4px
    );
  }
  ${({ active, img }) => {
    return [
      !active ? tw`opacity-60` : tw`opacity-90`,
      img && [
        `background-image: url(${img});`,
        `background-size: cover;`,
        `background-repeat: no-repeat;`
      ]
    ];
  }};
`;
const Sticky = styled.div`
  ${tw`
    xl:pt-2
    xl:sticky
    bg-brand-primary
    top-0
    relative
    z-20
  `};
`;
const StickyFilters = styled.div`
  ${tw`
    xl:pt-2.5
    xl:sticky
    bg-brand-primary
    top-0
    lg:top-4
    relative
    z-10
  `};
`;
const EmptySpace = styled.div`
  ${tw`
    h-0
    md:h-1
    lg:h-1.5
    xl:h-2
  `};
`;
const wavesMotionTwo = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right--2px
    w-full
    z-10
    h-1.5
    md:h-2.5
    bottom-0
    overflow-hidden
  `};
  svg {
    position:relative;
    width: 100%;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:20%;
    max-height:100%;
    g {
      > use {
        animation: ${wavesMotionTwo} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        ${tw`
          opacity-50
          fill-gray-800
        `};
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        ${tw`
          opacity-40
          fill-gray-800
        `};
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        ${tw`
          opacity-20
          fill-gray-800
        `};
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        ${tw`
          opacity-100
          fill-gray-800
        `};
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

function compare (a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      entries: 4,
      currentCase: ``,
      casesFilter: null,
      modal: null
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleHoverCase = this.handleHoverCase.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleFilter (filter) {
    this.setState({
      casesFilter: filter === `all` ? null : filter
    })
  }

  handleHoverCase (target) {
    this.setState({
      currentCase: target
    })
  }

  handleModal (id) {
    const { modal } = this.state;
    this.setState({
      modal: id !== modal ? id : null
    })
  }

  render () {
    const { 
      label,
      ordinal,
      prettyTitle,
      content,
      portfolio
    } = this.props;
    let { currentCase, casesFilter } = this.state;

    // logger.verbose(`currentCase`, currentCase);
    if (!currentCase) {currentCase = portfolio[0].node.id};
    // logger.verbose(`currentCase after`, currentCase);

    let categoriesList = [];
    portfolio.forEach((edge) => {
      const { categories } = edge.node;
      categoriesList = [...categoriesList, ...categories];
    });
    categoriesList = _.uniqBy(categoriesList, 'id');
    categoriesList = _.compact(categoriesList).sort(compare);
    return (
      <Section id={`work`}>
        <Container fluid>
          <Row>
            <ColList>
              <Sticky>
                <Fade>
                  <SectionLabel label={label} ordinal={ordinal} color={`black`} cropBottom />
                </Fade>
              </Sticky>
              {prettyTitle && (
                <EmptySpace />
              )}
              {prettyTitle && (
                <Fade top distance={`0.5em`} duration={400}>
                  <SectionTitle
                    title={prettyTitle.childMdx ? prettyTitle.childMdx.rawBody : `Work`}
                    content={content}
                  />
                </Fade>
              )}
              <Fade bottom distance={`0.5em`} cascade duration={400}>
                <StickyFilters>
                  <Filters>
                    <Filter
                      active={!casesFilter}
                      onClick={() => this.handleFilter(`all`)}
                    >
                      All
                    </Filter>
                    { categoriesList.map((category) => {
                      return (
                        <Filter
                          key={`filter-${category.id}`}
                          onClick={() => this.handleFilter(category.slug)}
                          active={casesFilter === category.slug}
                        >
                          {category.title}
                        </Filter>
                      )
                    })}
                  </Filters>
                  <LineSeparator cropBottom />
                </StickyFilters>
                <Projects>
                  {portfolio.map((edge) => {
                    const {
                      id,
                      title,
                      client,
                      externalLink,
                      categories,
                      featuredImage
                    } = edge.node;
                    const showCase = !casesFilter ? true : _.some(categories, {slug: casesFilter});
                    return (
                      <Project
                        key={`proyectList-${id}`}
                        onMouseEnter={() => this.handleHoverCase(id)}
                        showCase={showCase}
                        active={currentCase === id}
                      >
                        {featuredImage && (
                          <ProjectImageMobile img={featuredImage.fixed.src} active={currentCase === id}>
                            {externalLink && (
                              <a href={externalLink} target={`_blank`} title={`${client.name}'s ${title}`}>
                                {`Launch Project 🔗`}
                              </a>
                            )}
                          </ProjectImageMobile>
                        )}
                        <ProjectContent>
                          {client && client.name}
                          <ProjectTitle>
                            {title}
                          </ProjectTitle>
                        </ProjectContent>
                        {externalLink && (
                          <LinkScreen href={externalLink} target={`_blank`} title={`${client.name}'s ${title}`} />
                        )}
                      </Project>
                    )
                  })}
                </Projects>
              </Fade>
            </ColList>
            <ColImage>
              {portfolio.map((edge,i) => {
                const {
                  id,
                  featuredImage
                } = edge.node;
                return (
                  featuredImage && (
                    <ColBgImage
                      key={`case-bgImg-${id}`}
                      imgSrc={featuredImage.fixed.src}
                      active={currentCase === id}
                      id={id}
                    />
                  )
                )
              })}
            </ColImage>
          </Row>
        </Container>
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" />
              <use xlinkHref="#gentle-wave" x="48" y="5" />
              <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
