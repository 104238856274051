import React from 'react';
// import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import tw from 'tailwind.macro';
// import Scroll from 'react-scroll';
// import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import Waypoint from 'react-waypoint';
import MediaQuery from 'react-responsive';

// import Color from 'styles/Color';
// import Screens from 'styles/Screens';
import logger from 'utils/logger';

import { Menu } from 'components/Navigation';
import {
  PageLoader
} from 'components/Elements';

import {
  SectionAbout,
  SectionClients,
  SectionContact,
  SectionLanding,
  SectionWhatWeDo,
  SectionHowWeWork,
  SectionWork
} from 'components/Sections';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const FixedDummy = styled.div`
  ${tw`
    bg-gray-800
    text-white
    flex
    min-h-screen
    content-center
    flex-wrap
    px-0
    relative
  `};
`;

// const Mobile = ({ children }) => {
//   if (typeof window !== 'undefined') {
//     const isMobile = useMediaQuery({ maxWidth: 899 })
//     return isMobile ? children : null
//   }
//   return (null);
// }
// const Default = ({ children }) => {
//   if (typeof window !== 'undefined') {
//     const isNotMobile = useMediaQuery({ minWidth: 900 })
//     return isNotMobile ? children : null
//   }
//   return (null);
// }

class Index extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      progress: 0,
      loading: true,
      scrollTop: 0,
      scrollDirection: null,
      waypoint: null
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount () {

    this.myInterval = setInterval(() => {
      const {
        loading,
        progress
      } = this.state;

      if (progress < 170) {
        if (progress < 20 && progress > 9) {
          this.setState(() => ({
            progress: progress + 1
          }))
        }
        else if (progress >= 20 && progress < 25) {
          this.setState(() => ({
            progress: progress + 0.5
          }))
        }
        else if (progress >= 25 && progress < 50) {
          this.setState(() => ({
            progress: progress + 2
          }))
        }
        else {
          this.setState(() => ({
            progress: progress + 3
          }))
        }
      }
      if (loading && progress > 149) {
        this.setState(() => ({
          loading: false
        }))
      }
    }, 50);

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
    clearInterval(this.myInterval);
  }

  handleScroll (event) {
    const { scrollY } = typeof window !== 'undefined' && window;
    const { scrollTop } = this.state;
    this.setState({
      scrollTop: scrollY,
      scrollDirection: scrollTop < scrollY ? `down` : `up`
    });
  }

  handleWaypointEnter (enter, previousPosition, currentPosition) {
    // const { waypoint } = this.state;
    let target;
    if (enter === `Landing` && previousPosition === `above` || enter === `Landing` && !previousPosition) target = `Landing`;

    if (enter === `About` && previousPosition === `above` /* || enter === `About` && !previousPosition */) target = `About`;
    // if (enter === `About` && previousPosition === `below`) target = `Landing`;

    if (enter === `Work` && previousPosition === `above` || enter === `Work` && !previousPosition) target = `Work`;
    // if (enter === `Work` && previousPosition === `below`) target = `About`;

    if (enter === `HowWeWork` && previousPosition === `above` || enter === `HowWeWork` && !previousPosition) target = `HowWeWork`;
    // if (enter === `HowWeWork` && previousPosition === `below`) target = `Work`;

    if (enter === `WhatWeDo` && previousPosition === `above` || enter === `WhatWeDo` && !previousPosition) target = `WhatWeDo`;
    // if (enter === `WhatWeDo` && previousPosition === `below`) target = `HowWeWork`;

    if (enter === `Clients` && previousPosition === `above` || enter === `Clients` && !previousPosition) target = `Clients`;
    // if (enter === `Clients` && previousPosition === `below`) target = `WhatWeDo`;

    if (enter === `Contact` && previousPosition === `above` || enter === `Contact` && !previousPosition) target = `Clients`;

    if (target) {
      this.setState({
        waypoint: target
      })
    }
  }

  handleWaypointLeave (leave, previousPosition, currentPosition) {
    // const { waypoint } = this.state;
    let target;
    if (leave === `Landing` && currentPosition === `above`) target = `About`;

    if (leave === `About` && currentPosition === `above`) target = `Work`;
    if (leave === `About` && currentPosition === `below`) target = `Landing`;

    if (leave === `Work` && currentPosition === `above`) target = `HowWeWork`;
    if (leave === `Work` && currentPosition === `below`) target = `About`;

    if (leave === `HowWeWork` && currentPosition === `above`) target = `WhatWeDo`;
    if (leave === `HowWeWork` && currentPosition === `below`) target = `Work`;

    if (leave === `WhatWeDo` && currentPosition === `above`) target = `Clients`;
    if (leave === `WhatWeDo` && currentPosition === `below`) target = `HowWeWork`;

    if (leave === `Clients` && currentPosition === `above`) target = `Contact`;
    if (leave === `Clients` && currentPosition === `below`) target = `WhatWeDo`;

    if (leave === `Contact` && currentPosition === `below`) target = `Clients`;

    this.setState({
      waypoint: target
    });
  }

  render () {
    const {
      data,
      sectionLanding
    } = this.props;
    logger.gql(data);
    const {
      progress,
      loading,
      scrollDirection,
      scrollTop,
      waypoint
    } = this.state;

    const siteTitle = data.site.siteMetadata.title;

    const page = data.contentfulPage;
    const clients = data.allContentfulClient.edges;
    const portfolio = data.allContentfulCaseStudy.edges;
    const services = data.allContentfulService.edges;
    const { blocks } = page.fields;
    // const contacts = data.allContentfulContact.edges;

    let heroWho = {};
    let heroServices = {};
    let heroContact = {};
    
    const darkMenu = [
      // `Landing`,
      `About`,
      `Work`,
      `HowWeWork`,
      `WhatWeDo`,
      // `Clients`,
      `Contact`,
    ];

    const waypontColorScheme = darkMenu.includes(waypoint) ? `dark` : `light`;

    return (
      <Layout
        title={siteTitle}
        location={sectionLanding}
        onScroll={() => this.handleScroll()}
      >
        <SEO
          title={`PAWA | Brand Consultancy`}
          keywords={[
            `pawa`,
            `design`,
            `branding`,
            `thinklo`,
            `innovation`,
            `consulting`,
            `web`,
            `digital`,
            `dcustomer experience`,
            `cx`,
            `agency`
          ]}
        />
        <Menu
          loading={loading}
          landing={waypoint === `Landing`}
          waypoint={waypoint}
          colorScheme={waypontColorScheme}
          scrollDirection={scrollDirection}
        />
        {loading ? (
          <PageLoader progress={progress} />
        ) : (
          blocks.map((block, i) => {
            if (block.contentful_id === `3O50HPuBYvQZOj8hzuy2OU`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`Landing`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`Landing`, previousPosition, currentPosition)}
                  topOffset={`110px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionLanding {...block} />
                  </div>
                </Waypoint>
              )
            }
            if (block.contentful_id === `1eggtSvyWpJowTGpb6cRhL`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`About`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`About`, previousPosition, currentPosition)}
                  topOffset={`20px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionAbout {...block} hero={heroWho} ordinal={`0${i}`} />
                  </div>
                </Waypoint>
              )
            }
            if (block.contentful_id === `4KtcHC7gjfERExywomJ9oy`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`Work`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`Work`, previousPosition, currentPosition)}
                  topOffset={`20px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionWork {...block} portfolio={portfolio} ordinal={`0${i}`} />
                  </div>
                </Waypoint>
              )
            }
            if (block.contentful_id === `6beyBUENDu842xxEUsIkgy`) {
              return [
                <MediaQuery key={`m-${block.contentful_id}`} maxWidth={1199}>
                  <Waypoint
                    onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`HowWeWork`, previousPosition, currentPosition)}
                    onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`HowWeWork`, previousPosition, currentPosition)}
                    topOffset={`20px`}
                    fireOnRapidScroll
                  >
                    <div style={{ position: `relative` }}>
                      <SectionHowWeWork {...block} hero={heroWho} ordinal={`0${i}`} />
                    </div>
                  </Waypoint>
                </MediaQuery>,
                <MediaQuery key={block.contentful_id} minWidth={1200}>
                  <Waypoint
                    onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`HowWeWork`, previousPosition, currentPosition)}
                    onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`HowWeWork`, previousPosition, currentPosition)}
                    topOffset={`20px`}
                    fireOnRapidScroll
                  >
                    <div style={{ position: `sticky`, top: `0` }}>
                      <SectionHowWeWork {...block} hero={heroWho} ordinal={`0${i}`} />
                    </div>
                  </Waypoint>
                </MediaQuery>,
                <MediaQuery key={`sp-${block.contentful_id}`} minWidth={1200}>
                  <div style={{ height: `70vh` }} />
                </MediaQuery>
              ]
            }
            if (block.contentful_id === `7nWZvpRNZjyq4iRSgAsaVf`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`WhatWeDo`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`WhatWeDo`, previousPosition, currentPosition)}
                  topOffset={`20px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionWhatWeDo {...block} hero={heroServices} services={services} ordinal={`0${i}`} />
                  </div>
                </Waypoint>
              )
            }
            if (block.contentful_id === `5RnXP0lhayb4MBOTjk1BtF`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`Clients`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`Clients`, previousPosition, currentPosition)}
                  topOffset={`20px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionClients {...block} clients={clients} ordinal={`0${i}`} />
                  </div>
                </Waypoint>
              )
            }
            if (block.contentful_id === `XG3oluYg36dxencdpRChh`) {
              return (
                <Waypoint
                  key={block.contentful_id}
                  onEnter={({ previousPosition, currentPosition }) => this.handleWaypointEnter(`Contact`, previousPosition, currentPosition)}
                  onLeave={({ previousPosition, currentPosition }) => this.handleWaypointLeave(`Contact`, previousPosition, currentPosition)}
                  topOffset={`20px`}
                  fireOnRapidScroll
                >
                  <div style={{ position: `relative` }}>
                    <SectionContact {...block} hero={heroContact} contacts={[]} ordinal={`0${i}`} />
                  </div>
                </Waypoint>
              )
            }
            return (``);
          })
        )}
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: {eq:"landing"}) {
      title
      slug
      fields {
        blocks {
          ... Blocks
        }
      }
    }
    allContentfulClient(sort: {fields: sort, order: ASC}, limit: 400){
      edges{
        node{
          ... Client
        }
      }
    }
    allContentfulCaseStudy(sort: {fields: sort, order: ASC}, limit: 8){
      edges{
        node{
          id
          title
          sort
          externalLink
          client{
            name
          }
          serviceAreas{
            id
            title
            slug
          }
          categories{
            id
            title
            slug
          }
          featuredImage{
            ... FeaturedImage
          }
        }
      }
    }
    allContentfulService(sort: {fields: sort, order: ASC}) {
      edges{
        node{
          ... Service
        }
      }
    }
  }
`;
