import React from 'react';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import ReactPlayer from 'react-player';
// import SwipeableViews from 'react-swipeable-views';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';

import { bindKeyboard } from 'react-swipeable-views-utils';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import { ReactComponent as THINKLO } from 'assets/THINKLO-logo-light.svg';

import {
  LineSeparator
} from 'components/Elements';

const BindKeyboardSwipeableViews = bindKeyboard(Slider);

import {
  SectionLabel,
  SectionTitle
} from 'components/Text';

const easing = 500;

const transition = css`
  transition: all ${easing}ms ease;
`;
const Section = styled.section`
  ${tw`
    bg-gray-800
    text-white
    flex
    content-center
    flex-wrap
    px-1
    lg:px-0
    relative
    top-0
    relative
    overflow-x-hidden
  `};
  .slick-slider {
    ${tw`
      min-h-screen
      md:min-h-screen-50
      lg:min-h-screen
    `}
  }
`;
const ColText = styled.div`
  ${tw`
    flex
    md:min-h-screen-50
    lg:min-h-screen
    xl:min-h-screen
    py-3
    md:py-4
    lg:py-5
    xl:py-6
    md:w-11/12
    lg:w-2/3
    xl:w-1/2
    md:px-1/18
    lg:px-1/12
    flex-2
    w-full
  `};
`;
const ColImage = styled.div`
  ${tw`
    flex
    h-1px
    lg:h-screen
    w-1px
    lg:w-1/3
    xl:w-1/2
    flex-2
    text-center
  `};
`;
const Content = styled.div`
  ${tw`
    self-center
    relative
    top--1
    w-full
  `};
`;
const Title = styled.h1`
  ${tw`
    tracking-tight
    font-thin
    lg:text-superhero-xl
    xl:text-superhero-2lg
    xl:left--0.75
    leading-0.8
    pt-1
    relative
    mb-2
    lg:mb-3
  `};
  b {
    ${tw`
      font-bold
    `}
  }
  svg {
    ${tw`
      relative
      top-12px
      lg:top-8px
      fill-current
      h-2.5
      md:h-4
      xl:h-5
      mb-1
    `}
  }
  em {
    ${tw`
      not-italic
      lg:text-superhero-2xl
      xl:text-superhero-3xl
    `}
  }
`;
const Subtitle = styled.div`
  ${tw`
    font-light
    tracking-wider
    text-gray-300
    text-sm
    md:text-lg
    lg:text-lg
    leading-2
  `};
  p {
    ${tw`
      leading-1.5
      md:leading-1.6
      mb-1
      md:mb-2
    `}
  }
  a {
    ${tw`
      text-gray-100
      hover:text-white
      my-0
      py-0
      leading-1.5
    `}
  }
  strong > a {
    ${tw`
      top-1
      lg:top-0.5
      block
      font-semibold
      text-sm
      tracking-wide
      text-gray-300
      hover:text-white
      relative
      bg-brand-primary
      w-0
      w-14px
      whitespace-no-wrap
      leading-0
      h-2px
    `}
    ${transition};
    text-indent: 24px;
    :hover{
      ${tw`
        tracking-widest
        w-40px
      `}
      ${transition};
      text-indent: 58px;
      :after,
      :before {
        ${tw`
          left-36px
        `}
      }
    }
    :after,
    :before {
      ${tw`
        absolute
        left-8px
        block
        bg-brand-primary
        h-2px
        w-8px
      `}
      ${transition};
      content: '';
    }
    :before {
      ${tw`
        top--2px
      `}
      transform: rotate(45deg);
    }
    :after {
      ${tw`
        top-2px
      `}
      transform: rotate(-45deg);
    }
  }
`;
const Slide = styled.div`
  ${tw`
    px-2
    min-h-3
    md:px-1/48
    xl:px-1/48
    cursor-grab
  `};
  ${({ grab }) => {
    return [
      grab && tw`cursor-grabbing`
      // !open ? tw`invisible` : tw`visible`
    ];
  }};
`;
const X = styled.span`
  ${tw`
    inline-block
    relative
    w-2
    pl-1
    lg:pl-0
    h-2
    lg:h-6
    lg:w-6
    xl:h-7
    xl:w-7
  `};
  &:before, &:after{
    ${tw`
      absolute
      bg-white
      transition-all
      transition-200
      transition-ease
      h-2
      lg:h-6
      xl:h-7
      w-2px
      lg:w-4px
      lg:top-4px
    `};
    content:'';
  }
  &:before{
    ${tw`
      lg:left-3
      xl:left-3.5
    `};
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
  &:after{
    ${tw`
      lg:left-3
      xl:left-3.5
    `};
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
`;
const Prev = styled.div`
  ${tw`
    absolute
    hidden
    xl:flex
    transition-all
    transition-600
    transition-ease
    z-10
    cursor-pointer
    opacity-60
    hover:opacity-100
    xl:w-1/16
    top-5
    bottom-5
    left-0
    content-center
  `};
  border-radius: 0 1em 1em 0;
  :hover, :focus {
    ${tw`xl:w-1/20`};
    background-color: rgba(0,0,0,0.4);
  };
  :after,
  :before {
    ${tw`
      hidden
      lg:block
      ml--0.5
      w-2px
      bg-white
      absolute
    `};
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate3D(50%,50%,0);
    content: '';
  }
  :before {
    ${tw`
    `}
    margin-top: 20px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
  :after {
    ${tw`
      mb--2
    `}
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
`;
const Next = styled.div`
  ${tw`
    absolute
    hidden
    lg:flex
    transition-all
    transition-600
    transition-ease
    z-10
    cursor-pointer
    opacity-60
    hover:opacity-100
    xl:w-1/16
    top-5
    bottom-5
    right-0
    content-center
  `};
    border-radius: 1em 0 0 1em;
  :hover, :focus {
    ${tw`xl:w-1/20`};
    background-color: rgba(0,0,0,0.4);
  };
  :after,
  :before {
    ${tw`
      hidden
      lg:block
      ml-0.5
      w-2px
      bg-white
      absolute
    `};
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate3D(50%,50%,0);
    content: '';
  }
  :before {
    ${tw`
    `}
    margin-top: 20px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
  :after {
    ${tw`
      mb--2
    `}
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
`;
const SlideDots = styled.ul`
  ${tw`
    absolute
    bottom-0
    lg:bottom-5
    lg:left-1/12
    pl-2
  `}
  li {
    ${tw`
      relative
      left--2px
      inline-block
      cursor-pointer
      rounded-full
      mr-12px
      w-24px
      h-4px
      border-3
      border-solid 
      transition-all
      transition-500
      transition-ease
      border-gray-600
      hover:border-gray-400
    `}
    &.slick-active{
      ${tw`
        bg-brand-primary
        hover:bg-brand-primary
        border-brand-primary
      `}
    }
  }
`;
const SlideDot = styled.li`
  ${tw`
    relative
    left--2px
    inline-block
    cursor-pointer
    rounded-full
    mr-12px
    w-24px
    h-4px
    border-3
    border-solid 
    transition-all
    transition-500
    transition-ease
  `}
  ${({ imgSrc, active }) => {
    return [
      active ? tw`bg-brand-primary hover:bg-brand-primary border-brand-primary` : tw`border-gray-600 hover:border-gray-400`,
    ];
  }};
`;
const SlideContainer = styled(Slider)`
  ${tw`
    mb-1
    min-h-screen
  `}
  ${({ imgSrc, active }) => {
    return [
      // active ? tw`bg-brand-primary hover:bg-brand-primary border-brand-primary` : tw`border-gray-600 hover:border-gray-400`,
    ];
  }};
`;
const TitleMDX = styled.span``;

function nextChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}
nextChar('a');

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      entries: 4,
      modal: null,
      grab: null
    };
    this.handleGoTo = this.handleGoTo.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleSwipeEvent = this.handleSwipeEvent.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({
      index: value,
    });
  };

  handleChangeIndex = index => {
    this.setState({
      index,
    });
  };

  handleGoTo(target) {
    this.setState({
      index: target
    })
  }

  handlePrev() {
    const { index } = this.state;
    if (index > 0) {
      this.setState({
        index: index - 1
      })
    }
  }

  handleNext () {
    const { index } = this.state;
    if (index <  2) {
      this.setState({
        index: index + 1
      })
    }
  }

  handleSwipeEvent (){
    let grab = false;
    if (event.type === `mousedown` || event.type === `click`) {
      grab = true;
    }
    else {
      grab = false;
    }
    logger.debug(`handleMouse event type`, event.type, `grab`, grab)
    if (event.type === `mouseup`) {
      grab = false;
    }
    this.setState({ grab: grab });
  }

  handleTransitionEnd = () => {
    logger.debug(`handleTransitionEnd drag false`)
    this.setState({ grab: false });
  }

  render() {
    const {
      hero,
      ordinal,
      blocks,
    } = this.props;
    const {
      heading,
      content
    } = hero;
    let letter = `a`;
    const { index, grab } = this.state;
    const settings = {
      onSwipe: this.handleTransitionEnd,
      // enableMouseEvents: true,
      onMoseDown: () => this.handleSwipeEvent(),
      onMouseUp: () => this.handleSwipeEvent(),
      onClick: () => this.handleSwipeEvent(),
      // index: index,
      // onChangeIndex: this.handleChangeIndex,
      // disableLazyLoading: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      adaptiveHeight: true,
      prevArrow: <Prev />,
      nextArrow: <Next />,
      dots: true,
      variableWidth: true
    };
    return (
      <Section id={`how`}>
        <SlideContainer ref={slider => (this.slider = slider)} {...settings} >
          {blocks && blocks.map((slide, i) => {
            if (i > 1) {
              letter = nextChar(letter);
            }
            return (
              <Slide key={`slide-${slide.id}`} index={i} grab={grab}>
                <Container fluid>
                  <Row>
                    <ColText>
                      <Content>
                        <Fade bottom distance={`0.5em`}>
                          <SectionLabel label={slide.label ? slide.label : `How we Rock!`} ordinal={`${ordinal}${i > 0 ? letter : ``}`} color={`white`} width={i > 0 ? `226px` : `122px`} />
                          <Title>
                            {slide.prettyTitle && (
                              slide.prettyTitle.childMdx && (
                                <MDXProvider
                                  components={{
                                    p: TitleMDX,
                                    X: X,
                                    THINKLO: THINKLO,
                                  }}
                                >
                                  <MDXRenderer>
                                    {slide.prettyTitle.childMdx.body}
                                  </MDXRenderer>
                                </MDXProvider>
                              )
                            )}
                          </Title>
                          {slide.content && (
                            slide.content.childMdx && (
                              <Subtitle>
                                <MDXRenderer>
                                  {slide.content.childMdx.body}
                                </MDXRenderer>
                              </Subtitle>
                            )
                          )}
                        </Fade>
                      </Content>
                    </ColText>
                    <ColImage>
                      <Content>
                        {slide.featuredImage && (
                          <img src={slide.featuredImage.file.url} alt={slide.prettyTitle && slide.prettyTitle.excerpt} />
                        )}
                      </Content>
                    </ColImage>
                  </Row>
                </Container>
              </Slide>
            )
          })}
        </SlideContainer>
        {/* 
        <SlideDots>
          {blocks && blocks.map((slide, i) => {
            return (
              <SlideDot key={`slideDot-${slide.id}-${i}`} active={index === i} onClick={() => this.handleGoTo(i)} />
            )
          })}
        </SlideDots>
        {index > 0 && (
          <Prev onClick={() => this.handlePrev()} />
        )}
        {(index + 1) < blocks.length && (
          <Next onClick={() => this.handleNext()} />
        )}
        */}
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
