import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

// import logger from 'utils/logger';
// import TextTransition, { presets } from "react-text-transition";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import { Container, Row } from 'components/GridSystem';

import {
  SectionLabel
} from 'components/Text';

const Section = styled.section`
  ${tw`
    bg-gray-800
    text-white
    flex
    py-4
    lg:py-0
    min-h-screen-60
    lg:min-h-screen
    content-center
    flex-wrap
    px-2
    md:px-1/24
    lg:px-1/48
    xl:px-0
    relative
  `};
`;
const Content = styled.div`
  ${tw`
    xl:mx-1/12
    pb-1
    md:pb-2
    lg:pb-3
    w-full
  `};
`;
const Title = styled.div`
  ${tw`
    relative
    font-thin
    text-2xl
    pl-2
    pt-14px
    tracking-tight
    md:tracking-normal
    xl:px-1
    md:text-hero-xl
    lg:text-superhero
    xl:text-superhero-lg
    xl:w-4/5
    text-gray-200
    mb-1
    leading-1.11
  `};
  b, strong {
    ${tw`
      font-bold
      tracking-tight
    `};
  }
  .react-reveal {
    ${tw`
      leading-1.11
    `};
  }
`;
const Ordinal = styled.span`
  ${tw`
    absolute
    left--1/24
    md:left--2.5
    xl:left--3.5
    w-1/24
    text-center
    font-light
    tracking-wider
    top-14px
    lg:top-28px
    ml-6px
    text-lg
  `};
`;
const Effect = styled.div`
  ${tw`
    absolute
    left-0
    w-full
    h-2px
    bg-brand-primary
    z-10
  `};
  top: 20vh;
  mix-blend-mode: lighten;
`;
const TitleMDX = styled.span``;
const Text = styled.div`
  ${tw`
    mx-auto
    font-light
    tracking-wide
    leading-fit
    md:text-xl
    xl:text-2xl
  `};
`;
const wavesMotionTwo = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right-0
    w-full
    h-2
    lg:h-3
    bottom--24px
    lg:bottom--3
    z-20
    overflow-hidden
  `};
  svg {
    ${tw`
      min-h-12px
      max-h-24px
      lg:min-h-20px
      lg:max-h-40px
      relative
      w-full
    `};
    margin-bottom:-7px; /*Fix for safari gap*/
    transform: rotate(180deg);
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    g {
      > use {
        animation: ${wavesMotionTwo} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        ${tw`
          opacity-50
          fill-gray-800
        `};
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        ${tw`
          opacity-40
          fill-gray-800
        `};
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        ${tw`
          opacity-20
          fill-gray-800
        `};
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        ${tw`
          opacity-100
          fill-gray-800
        `};
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      spinnerText: 'creating',
      currentCount: 0,
      entries: 4,
      modal: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  // componentDidMount () {
  //   this.intervalId = setInterval(this.timer.bind(this), 2500);
  // }

  // componentWillUnmount () {
  //   clearInterval(this.intervalId);
  // }

  // timer () {
  //   const { currentCount } = this.state;
  //   let spinnerText = 'creating';
  //   if (currentCount === 2) {
  //     spinnerText = 'discovering';
  //   }
  //   if (currentCount === 3) {
  //     spinnerText = 'designing';
  //   }
  //   if (currentCount === 4) {
  //     spinnerText = 'developing';
  //   }
  //   if (currentCount === 5) {
  //     spinnerText = 'transforming';
  //   }
  //   const count = currentCount < 5 ? currentCount + 1 : 1;

  //   this.setState({
  //     currentCount: count,
  //     spinnerText
  //   })
  // }

  handleClick () {
    const { entries } = this.state;
    this.setState({
      entries: entries + 2
    })
  }

  handleModal (id) {
    this.setState({
      modal: id != this.state.modal ? id : null
    })
  }

  render () {
    const { content, ordinal } = this.props;
    const { entries, spinnerText, currentCount } = this.state;
    return (
      <Section id={`about`}>
        <Container fluid>
          <Fade left distance={`1em`} duration={400}>
            <Row>
              <Content>
                <Title>
                  <Ordinal>
                    {`${ordinal}.`}
                  </Ordinal>
                  {content && (
                    content.childMdx && (
                      <MDXProvider
                        components={{
                          p: TitleMDX
                        }}
                      >
                        <MDXRenderer>
                          {content.childMdx.body}
                        </MDXRenderer>
                      </MDXProvider>
                    )
                  )}
                </Title>
              </Content>
            </Row>
          </Fade>
        </Container>
        {/* <Effect /> */}
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" />
              <use xlinkHref="#gentle-wave" x="48" y="5" />
              <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
