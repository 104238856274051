import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const RhythmGrid = styled.div`
  ${tw`absolute h-full w-screen`};
  display: ${(props) => {
    const { active } = props;
    return active ? `block` : `none`;
  }};
  z-index: 10000;
  background-size: 1rem 1rem;
  background-image: linear-gradient(to right, rgba(0, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(0, 255, 255, 0.25) 1px, transparent 1px);
  pointer-events: none;
  user-select: none;
  &:before {
    content: " ";
    z-index: 10001;
    ${tw`absolute h-full w-full`};
    background-size: 1rem 1rem;
    background-image: radial-gradient(circle, rgba(0, 255, 255, 0.25) 1px, rgba(0, 0, 0, 0) 1px);
  &:after {
    content: " ";
    z-index: 10001;
    ${tw`absolute h-full w-full`};
    background-size: 100% 2rem;
    background-image: linear-gradient(to right, rgba(0, 255, 255, 0.15) 1px, transparent 1px), linear-gradient(to bottom, rgba(0, 255, 255, 0.5) 1px, transparent 1px);
  }
`;
const RhythmGridCenter = styled.div`
  ${tw`absolute h-full w-screen`};
  display: ${(props) => {
    const { active } = props;
    return active ? `block` : `none`;
  }};
  z-index: 10001;
  margin-left: -0.5rem;
  background-size: 50vw 50vh;
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0.8) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 0, 0.8) 1px, transparent 1px);
  pointer-events: none;
  user-select: none;
  &:before {
    content: " ";
    z-index: 10001;
    ${tw`absolute h-full w-full`};
    background-size: 8.33% 8.5rem;
    background-image: linear-gradient(to right, rgba(255, 255, 0, 0.18) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 0, 0.18) 1px, transparent 1px);
  }
  &:after {
    content: " ";
    z-index: 10001;
    ${tw`absolute h-full w-full`};
    background-size: 16.666% 17rem;
    background-image: linear-gradient(to right, rgba(255, 255, 0, 0.25) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 0, 0.25) 1px, transparent 1px);
  }
`;
export default class Component extends React.Component {
  render () {
    const { active } = this.props;

    return (
      <RhythmGrid active={active}>
        <RhythmGridCenter active={active} />
      </RhythmGrid>
    );
  }
}

Component.propTypes = {
  active: PropTypes.bool
};
Component.defaultProps = {
  active: false
};
