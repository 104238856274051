const rhythm = 1.6;

// const RhythmSteps = {
//   0.125: rhythm * 0.125,
//   0.25: rhythm * 0.25,
//   0.5: rhythm * 0.5,
//   0.75: rhythm * 0.75,
//   base: rhythm,
//   1: rhythm * 1,
//   1.25: rhythm * 1.25,
//   1.5: rhythm * 1.5,
//   1.75: rhythm * 1.75,
//   2: rhythm * 2,
//   3: rhythm * 3,
//   4: rhythm * 4,
//   5: rhythm * 5,
//   6: rhythm * 6,
//   7: rhythm * 7,
//   8: rhythm * 8,
//   9: rhythm * 9,
//   10: rhythm * 10
// };

const Rhythm = function calculateRhythm (number) {
  return `${number * rhythm}rem`;
};

// export default Rhythm;
module.exports = Rhythm;

/*

// Line-height
@mixin line-height($number) {
  line-height: #{ $number * $leading + 'px'};
  line-height: #{ $number * $leading-rem + 'rem'};
}
// Font-size
@mixin font-size($number) {
  font-size: #{ $number * $base + 'px'};
  font-size: #{ $number + 'rem'};
}
 */
