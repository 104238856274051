import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import ReactPlayer from 'react-player';
import Fade from 'react-reveal/Fade';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import PAWAurl, { ReactComponent as PAWA } from 'assets/PAWA-logo.svg';
import { ReactComponent as PAWAClip } from 'assets/PAWA-logo-clip.svg';
import { ReactComponent as PAWAFavIcon } from 'assets/PAWA-A.svg';

const Section = styled.section`
  ${tw`
    bg-white
    min-h-screen
    text-black
    flex
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
    relative
    border-0
    border-solid
    border-gray-800
    text-center
    w-full
    left-0
  `};
  :after {
    ${tw`
      absolute
      top-0
      left-0
      w-screen
      h-screen
      z-20
    `};
    pointer-events: none;
    content: "";
    box-shadow: inset 0 2em 6em 1em rgba(0,0,0,0.15);
  }
  border-bottom-width: 3em;
  video {
    ${tw`
      absolute
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }
`;
const Content = styled.div`
  ${tw`
    flex-2
    mx-auto
    text-center
    w-full
    z-1
    relative
  `};
`;
const Button = styled.button`
  ${tw`
    inline-block
    bg-transparent
    border-0
    text-gray-300
    hover:text-white
    font-bold
    tracking-wide
    px-1
    pb-1
    mb-0
    w-auto
    hover:w-auto
    lg:text-md
    uppercase
    relative
    transition-all
    transition-700
    hover:transition-400
    transition-ease-in-out
  `};
  width: auto !important;
  &:before,
  &:after{
    ${tw`
      absolute
      block
      h-4px
      transition-all
      transition-700
      transition-ease-in-out
    `};
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    bottom: 0;
    content: '';
  };
  &:before{
    ${tw`
      bg-brand-primary
      w-full
    `};
  };
  &:after{
    ${tw`
      bg-brand-secondary
      w-0
    `};
  };
  &:hover{
    &:after{
      ${tw`
        transition-400
        w-full
      `};
    }
  };
`;
const Logo = styled.h1`
  ${tw`
    bg-black
    mx-auto
    mb-1
    text-hero
    lg:text-superhero
    xl:text-superhero-2xl
    font-heavy
    relative
    h-5.5
    md:h-10
    lg:h-13
    lg:pb-10
    xl:pb-12
    xl:h-15
    xl:max-w-2/5
    overflow-hidden
  `};
  ${({ open, bgImg }) => {
    return [
      bgImg && (`
        background-image: url(${bgImg});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      `)
      // !open ? tw`invisible` : tw`visible`
    ];
  }};
  svg {
    ${tw`
      fill-white
      absolute
      top-0
      left-0
      w-full
      h-full
    `};
  }
`;
const Clip = styled.img`
  ${tw`
    w-full
    bg-gold
    bg-brand-primary
  `};
  clip-path: url(#logo);
  ${({ open }) => {
    return [
      // !open ? tw`invisible` : tw`visible`
    ];
  }};
`;
const ClipPath = styled.svg`
  ${tw`
    absolute
    top-0
    left-0
    w-full
  `};
  ${({ open }) => {
    return [
      // !open ? tw`invisible` : tw`visible`
    ];
  }};
`;

const Heading = styled.h2`
  ${tw`
    font-light
    tracking-widest
    md:tracking-wide
    lg:tracking-widest
    text-2xl
    leading-1.2
    md:text-3xl
    xl:text-3xl
    mb-0
    lg:mb-1
  `};
`;

const wavesMotion = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right-0
    z-10
    h-2
    lg:h-4
    bottom--24px
    lg:bottom--2.5
  `};
  transform: rotate(180deg);
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  svg {
    ${tw`
      min-h-12px
      max-h-24px
      lg:min-h-20px
      lg:max-h-40px
      relative
      w-full
    `};
    margin-bottom:-7px; /*Fix for safari gap*/
    g {
      > use {
        animation: ${wavesMotion} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: null
    };
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal (id) {
    this.setState({
      modal: id != this.state.modal ? id : null
    })
  }

  render () {
    const { featuredImage } = this.props;
    const background = {
      // video: `//videos.ctfassets.net/ht83b2g34cck/706xCbQOR7oZEibGaO7jNS/3ddf3f4367027834b466391a058d274f/landing-loop.mp4`
    }
    return (
      <Section>
        {background && (
          background.video && (
            <VideoBackground>
              <video autoPlay loop muted ref={(player) => { this.player = player }}>
                <source src={background.video} type="video/mp4" />
              </video>
            </VideoBackground>
          )
        )}
        <Container>
          <Row>
            <Content>
              <Fade top distance={`0.5em`} delay={300}>
                <Logo bgImg={featuredImage && featuredImage.file.url}>
                  <PAWAClip />
                </Logo>
              </Fade>
              <Fade bottom distance={`0.5em`} delay={300}>
                <Heading>
                  An exciting brand strategy consultancy.
                </Heading>
              </Fade>
            </Content>
          </Row>
        </Container>
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
