import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

      // 1px
const Number = styled.div`
  ${tw`
    overflow-hidden
    pl-0
    pr-1
    ml-0
    inline-block
    md:pl-1
    md:flex-2
    md:absolute
    md:-ml-1/12
    md:w-1/12
  `};
  &:after {
    content: "";
    ${tw`
      invisible
      md:visible
      flex
      absolute
      self-center
      w-4/5
      ml-0.5
      pt-px
      h-1/2
      float-right
    `};
    background-size: 0.75rem 1rem;
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.5) 1px, rgba(0, 0, 0, 0) 1px);
    top: 0.5rem;
    transform: translateY(1px);
  }
`;

export default class Component extends React.Component {
  render () {
    const { n } = this.props;
    return (
      <Number>
        {n < 10 && `0`}
        {n}
        .
      </Number>
    );
  }
}
Component.propTypes = {
  n: PropTypes.number
};
Component.defaultProps = {
  n: 1
};
