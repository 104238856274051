import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import {
  navigate,
} from 'gatsby';

const Button = styled.button`
  ${tw`
    rounded
    text-gray-800
    font-semibold
    transition-all
    transition-300
    transition-ease
    border-2
    border-solid
  `};
  ${(props) => {
    const {
      color,
      size,
      outline,
      uppercase,
      textColor
    } = props;
    const colorFind = colors[color];
    const colorParsed = Color(colorFind);
    const isLight = colorParsed.isLight();

    const colorTextFind = colors[textColor];

    return [
      size === `xl` && tw`py-1 px-4`,
      size === `lg` && tw`py-0.5 px-3 text-md rounded-lg`,
      size === `md` && tw`py-0.25 px-2 text-sm rounded-lg`,
      `border-color: ${colorFind};`,
      // outline && color && `border-color: ${colorFind};`,
      !outline && color && `background-color: ${colorFind};`,
      !outline && color && (!isLight ? tw`text-white` : tw`text-black`),
      outline && tw`bg-transparent`,
      outline && textColor ? `color: ${colorFind};` : `color: ${colorTextFind};`,
      uppercase && tw`uppercase`
    ];
  }};
  & :hover,
  & :focus {
    ${tw`
      text-white
      transition-all
      transition-150
      transition-ease
    `}
    ${(props) => {
    const {
      color,
      outline,
      darken,
      lighten,
    } = props;
    const colorFind = colors[color];
    const colorParsed = Color(colorFind);
    const isLight = colorParsed.isLight();
    const colorFocus = darken ? `${color}-600` : `${color}-400`;
    const colorFocusFind = colors[colorFocus];

    return [
      outline && color && `
        background-color: ${colorParsed.alpha(0.9)};
      `,
      !outline && color && (!isLight ? tw`text-white` : tw`text-black`),
      !outline && color && `background-color: ${colorFocusFind}; border-color: ${colorFocusFind};`
    ];
  }};
  }
`;
export default class Component extends React.Component {
  render () {
    const {
      label,
      color,
      size,
      navigateTo,
      outline,
      uppercase,
      darken,
      textColor,
      lighten
    } = this.props;
    const buttonProps = {
      color,
      size,
      outline,
      uppercase,
      darken,
      lighten
    };
    if (navigateTo) {
      buttonProps.onClick = () => navigate(navigateTo);
    }
    return (
      <Button {...buttonProps}>
        {label}
      </Button>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  reverse: PropTypes.bool
};
Component.defaultProps = {
  children: ``,
  reverse: false
};
