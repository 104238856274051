import React from 'react';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { ReactComponent as LogoIcon } from 'assets/PAWA-A-load.svg';
// import logger from 'utils/logger';

const transition = css`
  transition: all ${700}ms cubic-bezier(.1,.55,.4,1);
`;

const Loader = styled.div`
  ${tw`
    fixed
    top-0
    left-0
    w-full
    h-full
    bg-white
    text-black
    flex
    mb-0
    items-stretch
    content-center
    flex-wrap
    text-center
  `};
  :after {
    ${tw`
      absolute
      top-0
      left-0
      w-screen
      h-screen
      z-20
    `};
    pointer-events: none;
    content: "";
    box-shadow: inset 0 2em 6em 1em rgba(0,0,0,0.15);
  }
  > div {
    ${tw`
      mx-auto
    `};
    ${({ progress }) => {
      return [
        progress >= 100 ? tw`w-6 opacity-0` : tw`w-12 opacity-100`
      ];
    }};

    ${transition}
    * {
      ${transition}
    }
    .CircularProgressbar-path {
      ${tw`
        stroke-brand-primary
      `};
    }
    .CircularProgressbar-trail {
      ${tw`
        stroke-gray-200
      `};
    }
    .CircularProgressbar-text {
      fill: yellow;
    }
    .CircularProgressbar-background {
      fill: green;
    }
  }
`;
const Logo = styled(LogoIcon)`
  ${tw`
    h-5
    fill-black-alpha-70
    relative
    top--3
    transition-all
    transition-ease
    transition-200
  `};
  ${({ progress }) => {
    return [
      progress >= 100 ? tw`opacity-0` : tw`opacity-100`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const { progress } = this.props;
    return (
      <Loader progress={progress}>
        <div>
          <CircularProgressbarWithChildren
            value={progress}
            strokeWidth={2}
          >
            <Logo />
          </CircularProgressbarWithChildren>
        </div>
      </Loader>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
