import React from 'react';
import { connect } from 'react-redux';
// import {bindActionCreators} from 'redux';
import Fade from 'react-reveal/Fade';

import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
// import {
//   // StaticQuery,
//   // graphql,
//   navigate,
//   Link
// } from 'gatsby';
import { Link } from 'react-scroll';

import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Auth from 'modules/Auth0/Auth';
import logger from 'utils/logger';

import { ReactComponent as LogoText } from 'assets/PAWA-logo.svg';
import { ReactComponent as LogoIcon } from 'assets/PAWA-icon.svg';
import { navigateRedux } from 'modules/Redux/actions/navigate';
import { Container, Row } from 'components/GridSystem';

const auth = new Auth();

const easing = 500;

const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
`;

const Navigation = styled.div`
  ${tw`
    fixed
    w-0
    w-full
    h-4
    lg:h-6
    left-0
    text-left
    z-60
    transition-all
    transition-ease
    transition-300
  `};
  ${(props) => {
    const {
      menu,
      darkNav,
      scrollDirection,
      landing
    } = props;
    // logger.debug(`Navigation style props`, props)
    return [
      menu && `${transition};`,
      !darkNav ? tw`text-white md:bg-black-alpha-70` : tw`text-black md:bg-white-alpha-70`,
      scrollDirection === `down` && !menu ? tw`top--6` : tw`top-0`,
      (menu || landing) && tw`top-0`
    ];
  }};
  :before {
    ${tw`
      absolute
      h-6
      bg-transparent
      w-full
      bottom--6
    `};
    content: '';
  }
`;
const Nav = styled.nav`
  ${tw`
    flex
    w-full
    content-center
    my-0.5
    lg:my-0.75
    px-1/48
    h-4
  `};
`;
const Logo = styled(Link)`
  ${tw`
    block
    fixed
    top-1.5
    left-6px
    lg:left-1/48
    cursor-pointer
    h-2
    md:h-4
  `};
  svg {
    ${tw`
      fill-current
    `};
    ${transition};
  }
  ${({ darkNav }) => {
    return [
      darkNav ? tw`text-black` : tw`text-white`
    ];
  }};
`;
const LogoIconContainer = styled.span`
  ${tw`
    w-2
    md:w-4
    h-2
    md:h-4
    p-0
    inline-block
    relative
  `};
  svg {
    ${tw`
      relative
      left--4.5
      md:left--5.5
      top--0.5
      md:top-0
      pl-0.25
      md:pl-0
      h-2
      md:h-2.5
      m-0
    `};
  }
`;
const LogoTextContainer = styled.span`
  ${tw`
    invisible
    inline-block
    bg-gray-900
  `};
  svg {
    ${tw`
      absolute
    `};
    height: 14px;
    top: 11px;
    left: 8px;
  }
`;
const Menu = styled.ul`
  ${tw`
    w-full
    hidden
    md:flex
    flex-wrap
    content-center
    justify-end
    lg:ml-1/12
    lg:w-11/12
  `};
  ${({ darkNav }) => {
    return [
      // darkNav ? tw`text-black` : tw`text-white`,
    ];
  }};
`;

const MenuItem = styled.li`
  ${tw`
    mb-0
    px-1
    md:text-lg
    lg:text-xl
    relative
    top--8px
    md:top-0
    cursor-pointer
  `};
  a {
    transition: all ${300}ms cubic-bezier(.1,.55,.4,1);
    ${({ darkNav }) => {
      return [
        darkNav ? tw`text-gray-500 hover:text-black` : tw`text-gray-300 hover:text-white`
      ];
    }};
  }
`;

class Component extends React.Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (to) {
    navigateRedux(to);
  }

  render () {
    const CONSENT = Cookies.get('CONSENT');
    const consentStyleBox = process.env.GATSBY_CONSENT_BOX === `TRUE` && true;
    const displayConsent = !CONSENT || CONSENT < 1 && true;

    const {
      darkNav,
      menu,
      toggleMenu,
      scrollDirection,
      activeMenu,
      closeMenu,
      landing,
      loading
      /*navigateRedux*/
    } = this.props;
    const { isAuthenticated } = auth;
    const user = auth.getUser();

    // logger.verbose(`isAuthenticated: ${isAuthenticated}`, `user`, user);

    // logger.debug(`menu redux state`, this.props)

    const mainMenu = [
      {
        id: `1`,
        slug: `work`,
        label: `Work`
      },
      // {
      //   id: `2`,
      //   slug: `approach`,
      //   label: `Approach`
      // },
      {
        id: `3`,
        slug: `services`,
        label: `Services`
      },
      {
        id: `5`,
        slug: `contact`,
        label: `Contact`
      }
    ];
    return (
      <Navigation
        menu={menu}
        onMouseEnter={activeMenu}
        onMouseLeave={closeMenu}
        scrollDirection={scrollDirection}
        darkNav={darkNav}
        landing={landing}
      >
        <Container fluid>
          <Row>
            <Nav>
              <Logo onClick={toggleMenu} darkNav={darkNav} to={`/#about`}>
                <Fade when={!loading}>
                  <LogoIconContainer>
                    <LogoIcon />
                  </LogoIconContainer>
                  <LogoTextContainer>
                    <LogoText />
                  </LogoTextContainer>
                </Fade>
              </Logo>
              <Menu>
                { mainMenu.map((menuItem, i) => {
                  return (
                    <MenuItem key={`menuItem-${menuItem.id}`} darkNav={darkNav}>
                      <Fade top distance={`0.5em`} when={!loading} delay={i * 50}>
                        <Link
                          to={`${menuItem.slug}`}
                          smooth
                        >
                          {menuItem.label}
                        </Link>
                      </Fade>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Nav>
          </Row>
        </Container>
        {/*
        <div
          style={{
            position: `fixed`,
            top: `0`,
            right: `730px`,
            padding: `0 8px`,
            backgroundColor: `yellow`,
            zIndex: `1000`,
            color: `black`
          }}
        >
          darkNav: {darkNav ? `yes` : `no`}
          activeMenu: {menu ? `yes` : `no`}
          isLanding: {landing ? `yes` : `no`}
          scrollDirection: {scrollDirection}
        </div>
         */}
      </Navigation>
    );
  }
}

Component.propTypes = {
  menu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  activeMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  darkMenu: PropTypes.func.isRequired,
  lightMenu: PropTypes.func.isRequired,
  navigateRedux: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    menu,
    // darkNav
  } = state;
  // logger.debug(`state`, state)
  // logger.debug(`ownProps`, ownProps)
  let darkNav = true;
  if (ownProps.colorScheme !== `light`) {
    darkNav = false;
  }
  let landing = false;
  if (ownProps.landing) {
    landing = true;
  }
  return { menu, darkNav, landing };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }),
    darkMenu: () => dispatch({ type: `DARK_MENU` }),
    lightMenu: () => dispatch({ type: `LIGHT_MENU` }),
    // closeMenu: () => dispatch({ type: `CLOSE_MENU`, payload: ownProps }),
    activeMenu: () => dispatch({ type: `ACTIVE_MENU` }),
    closeMenu: () => dispatch({ type: `CLOSE_MENU` }),
    navigateRedux: () => dispatch(navigateRedux(ownProps))

  };
  // bindActionCreators({
  //   // getWantedList: getWantedList,
  //   navigateRedux: navigateRedux
  // }, dispatch);
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

// eslint-disable-next-line react/no-multi-comp
class ReduxComponent extends React.Component {
  render () {
    return (
      <ConnectedComponent {...this.props} />
    );
  }
}

export default ReduxComponent;

// export function navigateRedux (value) {
//   return {
//     type: `CLOSE_MENU`,
//     value // it will add key `value` with argument value. 
//   };
// }

