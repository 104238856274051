import tw from 'tailwind.macro';
import { createGlobalStyle } from 'styled-components';
import Rhythm from './Rhythm';
import Color from './Color';
// console.log(`Color global`, Color);

const mood = 'light';
const baseFontSize = `1rem`;
const lineHeight = 1.6;
const leading = 2;

const buttons = {
  transition: 200, // milisecond
};
  // @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
const GlobalStyle = createGlobalStyle`
  html, body {
    ${tw`
      relative
    `};
  }
  * {
    line-height: ${leading}rem;
    margin-bottom: ${leading}rem;
    ${tw`font-sans mt-0`}
    white-space: pre-line;
  }
  span {
    margin-bottom: 0;
  }
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .guides {
    & h1, 
    & h2, 
    & h3, 
    & h4, 
    & h5,
    & p,
    & small {
      position: relative;
      &:before {
        content: " ";
        ${tw`absolute w-full block`};
        height: 1px;
        background-size: 1rem 100rem;
        background-position-y: 50rem;
        background-image: radial-gradient(circle, rgba(0, 255, 255, 1) 1px, rgba(0, 0, 0, 0) 1px),
        linear-gradient(-90deg, rgba(255, 255, 0, 0.4) 0.8rem, transparent 1px);
      }
    }
  }
  article,
  div,
  section {
    margin-bottom: 0;
  }
  small {
    ${tw`font-sm leading-tight`}
  }
  p {
    & a {
      ${mood === `dark` ? tw`text-brand-primary-400` : tw`text-brand-primary-600`};
    }
    & a:hover,
    & a:focus {
      ${mood === `dark` ? tw`text-brand-primary-300` : tw`text-brand-primary-700`};
    }
  }
  h1, h2, h3, h4, h5 {
    ${tw`font-hero`}
  }
  h1 {
    ${tw`text-h1 leading-h1`}
    margin:0 0 3rem;
  }
  h2 {
    ${tw`
      text-h2
      leading-h2
      mb-1
      xs:mb-2
    `}
  }
  h3 {
    ${tw`text-h3 leading-h3`}
    margin:0 0 2rem;
  }
  h4 {
    ${tw`text-h4 leading-h4`}
    margin:0 0 ${Rhythm(1)};
  }
  h5 {
    ${tw`text-h5 leading-h5`}
    margin:0 0 1rem;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    ${mood === `dark` && tw`bg-black`};
    ${mood === `dark` && tw`text-white`};
  }
  html, body {
    ${tw`w-full h-full m-0 p-0`}
  }
  button,
  input[type="button"],
  input[type="submit"] {
    border: 0;
    cursor: pointer;
    &:focus {
      outline: 0 !important;
    }
  }
  a,
  a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }
  p {
    a,
    a:-webkit-any-link {
      transition:color ${buttons.transition}ms ease-out, background ${buttons.transition}ms ease-in;
      &:hover,
      &:focus {
        transition:color ${buttons.transition / 2}ms ease-out, background ${buttons.transition / 2}ms ease-in;
      }
    }
  }
  hr {
    ${tw`
      relative
      mb-1
      xs:mb-2
    `}
    background-color: rgba(125,125,125,.5);
    border-color: transparent;
    margin-top: -2px;
    top: 1px;
  }
  ::-moz-selection {
    background: ${Color[`brand-primary-400`]};
    color: white
  }

  ::selection {
    background: ${Color[`brand-primary-400`]};
    color: white
  }
  
  .slick-slider
  {
    ${tw`
      max-w-full
      max-h-full
    `}
      position: relative;


      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
        -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }

  .slick-list
  {
      position: relative;

      display: block;
      overflow: hidden;

      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }

  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;

      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }

  .slick-slide
  {
      display: none;
      float: left;
      max-width: 100vw;
      height: 100%;
      min-height: 1px;
  }
  .slick-track div:focus {
    outline: none;
    ${tw`
      cursor-grabbing
    `}
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;

      height: auto;

      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
  .slick-dots {
    ${tw`
      absolute
      bottom-0
      lg:bottom-5
      lg:left-1/12
      pl-2
    `}
    li {
      ${tw`
        relative
        inline-block
        mr-12px
      `}
      > button {
        ${tw`
          relative
          left--2px
          cursor-pointer
          rounded-full
          w-24px
          h-4px
          border-3
          border-solid 
          transition-all
          transition-500
          transition-ease
          border-gray-600
          hover:border-gray-400
          bg-gray-600
          hover:bg-gray-400
          overflow-hidden
        `}
      }
      &.slick-active > button{
        ${tw`
          bg-brand-primary
          hover:bg-brand-primary
          border-brand-primary
        `}
      }
    }
  }
`;

export default GlobalStyle;
// module.exports = GlobalStyle;
