import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Cookies from 'js-cookie';
import { Container, Row } from 'components/GridSystem';

const CookiesConsent = styled.aside`
  ${tw`
    fixed
    bg-black
    text-white
    block
    text-sm
    leading-tighter
    z-50
  `};
  ${({ box }) => {
    return [
      !box ? tw`w-full top-0 h-6 py-1.5` : tw`xl:w-1/5 px-2 py-2`,
      box && `bottom: 2rem; right: 2rem;`
    ];
  }};
  h4 {
    ${({ box }) => {
      return [
        !box ? tw`text-lg font-semibold mb-0` : tw`text-xl`
      ];
    }};
  }
  p {
    ${tw`leading-tight`};
    ${({ box }) => {
      return [
        !box ? tw`mb-0.5` : tw`mb-0.75`
      ];
    }};
  }
  button {
    ${tw`mb-0 px-0.75`};
  }
`;
const ColMessage = styled.div`
  ${tw`
    w-full
    md:w-7/12
    lg:w-3/5
    xl:w-4/5
  `};
`;
const ColButton = styled.div`
  ${tw`
    w-full
    md:w-5/12
    lg:w-2/5
    xl:w-1/5
    self-stretch
  `};
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      CONSENT: Cookies.get('CONSENT')
    };
    this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
  }

  handleAcceptCookies () {
    Cookies.set('CONSENT', 1);
    this.setState({
      CONSENT: Cookies.get('CONSENT')
    });
  }

  render () {
    const { box } = this.props;
    const { CONSENT } = this.state;
    const consentTitle = `This site use Cookies`;
    const consentMessage = `This site uses cookies to ensure we provide the best experience. By continuing to browse the site you are agreeing to our use of cookies.`;
    const consentButton = `Find out more here`;
    return (
      (!CONSENT || CONSENT < 1) && (
        <CookiesConsent box={box}>
          {box ? [
            consentTitle && <h4>{consentTitle}</h4>,
            <p>{consentMessage} <b onClick="">{consentButton}</b>.</p>,
            <button onClick={e => this.handleAcceptCookies()}>Accept</button>
          ] : (
            <Container>
              <Row reverse>
                <ColMessage>
                  {consentTitle && <h4>{consentTitle}</h4>}
                  <p>{consentMessage} <b onClick="">{consentButton}</b>.</p>
                </ColMessage>
                <ColButton>
                  <button onClick={e => this.handleAcceptCookies()}>Accept</button>
                </ColButton>
              </Row>
            </Container>
          )}
        </CookiesConsent>
      )
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
/*
Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our Privacy & Cookie Policy. You can adjust your preferences in Cookie Settings.


 */