import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// self-center
const Row = styled.div`
  ${tw`
    w-full
    flex
    flex-wrap
    items-stretch
    content-center
  `};
  ${({ reverse }) => {
    return [
      reverse ? tw`flex-row-reverse` : tw`flex-row`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const { children, reverse } = this.props;
    return (
      <Row reverse={reverse}>
        {children}
      </Row>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  reverse: PropTypes.bool
};
Component.defaultProps = {
  children: ``,
  reverse: false
};
