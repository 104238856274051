import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Title = styled.h1`
  ${tw`
    tracking-tight
    font-bold
    text-hero-xl
    lg:text-superhero-2xl
    pt-1
    mb-2
    md:mb-3
    relative
  `};
  ${(props) => {
    return [
      props.align === `center` && tw`text-center`,
      props.border && tw`border-gray-200 border-solid border-0 border-b`
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    md:font-light
    tracking-wide
    text-sm
    md:text-md
    lg:text-lg
  `};
  p {
    ${tw`
      leading-1.5
      md:leading-1.6
      mb-0
    `};
  }
  ${(props) => {
    return [
      props.align === `center` && tw`text-center`,
      props.border && tw`border-gray-200 border-solid border-0 border-b`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const {
      title,
      content
    } = this.props;
    return (
      <div>
        <Fade top distance={`2em`}>
          <Title {...this.props}>
            {title}
          </Title>
        </Fade>
        <Fade bottom distance={`2em`}>
          <Content {...this.props}>
            {content && (
              content.childMdx && (
                <MDXRenderer>
                  {content.childMdx.body}
                </MDXRenderer>
              )
            )}
          </Content>
        </Fade>
      </div>
    );
  }
}

Component.propTypes = {
  title: PropTypes.string
};
Component.defaultProps = {
  title: `Label`
};
