import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';

const BusinessFunction = styled.div`
  ${tw`
    flex-grow-2
    xl:w-11/24
    pb-3
    mb-3
  `};
  ${(props) => {
    return [
      props.border && [
        tw`
          border-gray-200
          border-solid
          border-0
          border-b
        `
      ]
    ];
  }};
  :nth-child(odd) {
    ${tw`
      xl:mr-1/12
    `};
  }
`;
const Title = styled.h4`
  ${tw`
      text-xl
      font-medium
  `};
`;
const Content = styled.p`
  ${tw`
    mb-0
    text-lg
    font-light
  `};
`;

export default class Component extends React.Component {
  render () {
    const {
      title,
      content,
      border
    } = this.props;
    return (
      <BusinessFunction border={border}>
        <Title>
          {title} {border}
        </Title>
        <Content>
          {content}
        </Content>
      </BusinessFunction>
    );
  }
}
Component.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  border: PropTypes.bool
};
Component.defaultProps = {
  title: 'Godwinian diplocardia merge shilfa',
  content: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  border: false
};
