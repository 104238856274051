import auth0 from 'auth0-js';
import { navigate } from 'gatsby'
import qs from 'qs';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';


import { AUTH_CONFIG } from './config';
import logger from 'utils/logger';

const cryptoKey = process.env.GATSBY_CRYPTO_KEY;

export default class Auth {
  auth0 = new auth0.WebAuth({
    audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
    scope: 'openid profile email',
    responseType: 'token id_token',
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    domain: AUTH_CONFIG.domain,
  })
 
  login = () => {
    this.auth0.authorize({}, function(err, response) {
      if (err) {
        logger.debug(err);
      } else {
        logger.debug(`this.auth0.authorize`, response);
      }
    });
  }
 
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  logout() {
    Cookies.remove('SSA');
    Cookies.remove('SSID');
    Cookies.remove('SSET');
    Cookies.remove('SSU');
    navigate('/'); 
  }

  handleAuthentication() {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash
      const querystring = qs.parse(window.location.search.substring(1));
      logger.debug(`handleAuthentication() > querystring`, querystring);
      const state = querystring.state;
      const authCookie = Cookies.get(`com.auth0.auth.${state}`);
      logger.debug(`handleAuthentication() > authCookie`, authCookie);
      const authCookieParse = authCookie ? JSON.parse(authCookie) : '';
      logger.debug(`handleAuthentication() > authCookieParse`, authCookieParse);

      const nonce = authCookieParse.nonce;
      const code = querystring.code;
      logger.debug(`handleAuthentication() > code`, code);
      logger.debug(`handleAuthentication() > nonce`, nonce);

      logger.debug(`handleAuthentication() > this.auth0`, this.auth0);

      logger.debug(`handleAuthentication() window.location`, window.location);
      logger.debug(`handleAuthentication() hash`, hash);

      
      this.auth0.parseHash(async (err, authResult) => {
        if (err) {
          logger.debug(`err`, err);
          // this.router.navigate('/']); 
        }

        else if (authResult && authResult.accessToken && authResult.idToken) { 
          window.location.hash = ''; 
          logger.debug(`authResult`, authResult)
          this.setSession(authResult); 
          // this.router.navigate('/']); 
        } 
        else {
          logger.debug(`wtf authResult`, authResult)
        }

        // Return to the homepage after authentication.
        // navigate('/account/profile');
      });
      /*
       this.auth0.parseHash((err, authResult) => { 
        if (err) {
          console.log(`err`, err);
          // this.router.navigate('/']); 
        }

        else if (authResult && authResult.accessToken && authResult.idToken) { 
          window.location.hash = ''; 
          this.setSession(`authResult`, authResult); 
          logger.debug(`authResult`, authResult)
          // this.router.navigate('/']); 
        } 
        else {
          logger.debug(`wtf authResult`, authResult)
        }
      });
       */



    }
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    if (typeof window !== 'undefined') {
      const expiresAt = JSON.parse(Cookies.get('SSET'));
      return new Date().getTime() < expiresAt;
    }
  }
  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         this.logout();
         logger.error(err);
         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
       }
    });
  }


  setSession(authResult) {
    const SSET = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    const expiresAt = new Date(new Date().getTime() +  (authResult.expiresIn * 1000));

    Cookies.set('SSA', authResult.accessToken, { expires: expiresAt });
    Cookies.set('SSID', authResult.idToken, { expires: expiresAt });
    Cookies.set('SSET', SSET, { expires: expiresAt });

    this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
      const permissions = typeof window !== 'undefined' && user[`${window.location.protocol}//${window.location.host}/`];
      let userData = user;
      userData.permissions = permissions;
      const cipherUser = CryptoJS.AES.encrypt(JSON.stringify(userData), cryptoKey);
      Cookies.set('SSU', cipherUser);
    })
  }

  getUser() {
    if (typeof window !== 'undefined') {
      const SSU = Cookies.get('SSU');
      if (SSU) {
        logger.verbose(`SSU`, SSU);
        const bytes = CryptoJS.AES.decrypt(SSU.toString(), cryptoKey);
        const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        logger.verbose(`SSU decrypt`, decrypt);
        return decrypt;
      }
    }
  }

  getUserName() {
    if (this.getUser()) {
      return this.getUser().name;
    }
  }
  
  getUserInfo() {
    if (this.getUser()) {
      return this.getUser();
    }
  }
}
