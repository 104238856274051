import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Screens from 'styles/Screens';
import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';
import {
  SectionLabel
} from 'components/Text';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
const TitleMDX = styled.span``;

const Section = styled.section`
  ${tw`
    bg-gold-300
    pt-3
    md:pt-4
    lg:pt-6
    xl:pt-8
    pb-1
    lg:pb-2
    xl:pb-4
    px-3
    md:px-1/12
    lg:px-1/14
    xl:px-1/48
  `};
`;
const Clients = styled.div`
  ${tw`
    ml-0
    w-full
    pb-1
    md:pb-2
    lg:pb-4
  `};
`;
const Client = styled.div`
  ${tw`
    bg-gold-300
    mr--2px
    mb--2px
    pt-0.5
    pb-0
    px-1
    w-full
    w-1/2
    h-6
    md:h-12
    md:w-1/2
    lg:w-1/5
    xl:w-1/5
    block
    border-solid
    border
    border-gold-400
    relative
  `};
  ${({ src }) => {
    return [
      src && (`
        :before {
          background-image: linear-gradient(0deg, #E9D594, #E9D594), url('${src}');
        }`),
      src && (`
        :after {
          background-image: url('${src}');
        }`)
    ];
  }};
  &:hover,
  &:focus {
    :before {
      ${tw`
          opacity-0
      `};
    }
    :after {
      ${tw`
          opacity-100
      `};
    }
  }
  :after {
    ${tw`
        opacity-0
    `};
    background-color: transparent;
  }
  :before {
    ${tw`
        opacity-70
    `};
    background-color: white;
    background-blend-mode: multiply, luminosity;
  }
  :before,
  :after {
    ${tw`
      absolute
      top-1
      bottom-1
      left-1
      right-1
    `};
    ${transition}
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
const Content = styled.div`
  ${tw`
    xl:mx-1/12
    pb-1
    md:pb-2
    lg:pb-3
    w-full
  `};
`;
const Button = styled.button`
  ${tw`
    block
    w-full
    md:w-auto
    md:w-auto
    md:inline-block
    bg-transparent
    border-0
    text-gold-700
    font-semibold
    hover:text-black
    tracking-superwide
    uppercase
    px-1
    pb-0.75
    mb-0
    text-sm
    relative
  `};
  ${transition};
  &:before,
  &:after{
    ${tw`
      absolute
      block
      h-2px
      transition-all
      transition-700
      transition-ease-in-out
    `};
    left: 0;
    bottom: 0;
    content: '';
  };
  &:before{
    ${tw`
      bg-gold-500
      w-full
    `};
  };
  &:after{
    ${tw`
      bg-gold-200
      w-0
    `};
  };
  &:hover{
    &:after{
      ${tw`
        transition-400
        w-full
      `};
    }
  };
`;
const ClientList = styled.ul`
  ${tw`
    w-full
    overflow-hidden
    transition-all
    transition-ease-out
  `};
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  @media only screen and (min-width: ${Screens.lg}){
    columns: 5;
    -webkit-columns: 5;
    -moz-columns: 5;
  }
  ${({ open }) => {
    return [
      !open ? tw`h-0 pt-0 lg:pb-2 opacity-0 transition-200` : tw`h-auto pt-2 lg:pt-5 lg:pb-2 opacity-100 transition-400`
    ];
  }};
`;
const ClientListItem = styled.li`
  ${tw`
    inline-block
    leading-1.4
    pr-1.5
    md:pr-0
    mb-0.5
    md:block
    md:mb-1
    text-sm
    md:text-md
    relative
  `};
  div {
    ${tw`
      leading-1.4
    `}
  }
  ${transition}
  ${({ open }) => {
    return [
      // !open ? tw`opacity-0 top--0.5` : tw`opacity-100 top-0`
    ];
  }};
`;
const SectionTitle = styled.h1`
  ${tw`
    tracking-tight
    font-thin
    text-5xl
    lg:text-superhero-xl
    lg:leading-0.8
    py-1
    mb-1
    lg:mb-2
    xl:py-2
    relative
    xl:max-w-2/3
    text-gold-800
  `};
  b {
    ${tw`
      text-black
      font-bold
    `};
  }
`;
const wavesMotionTwo = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;
const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right--2px
    w-full
    top--4px
    lg:top--0.5
    overflow-hidden
    h-2
    lg:h-3
  `};
  transform: rotate(180deg);
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  svg {
    ${tw`
      min-h-12px
      max-h-24px
      lg:min-h-20px
      lg:max-h-40px
      relative
      w-full
    `};
    margin-bottom:-7px; /*Fix for safari gap*/
    g {
      > use {
        animation: ${wavesMotionTwo} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        ${tw`
          opacity-50
          fill-gray-800
        `};
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        ${tw`
          opacity-40
          fill-gray-800
        `};
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        ${tw`
          opacity-20
          fill-gray-800
        `};
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        ${tw`
          opacity-100
          fill-gray-800
        `};
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showList: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    const { showList } = this.state;
    this.setState({
      showList: !showList
    })
  }

  render () {
    const {
      clients,
      label,
      ordinal,
      prettyTitle
    } = this.props;
    const { showList } = this.state;
    logger.debug(`clients`, clients)

    return (
      <Section id={`clients`}>
        <Container fluid>
          <Row>
            <Content>
              <Fade top distance={`2em`}>
                <SectionLabel label={`More than clients!`} ordinal={ordinal} color={`black`} width={`158px`} />
              </Fade>
              <Fade bottom distance={`2em`}>
                <SectionTitle>
                  {prettyTitle && (
                    prettyTitle.childMdx && (
                      <MDXProvider
                        components={{
                          p: TitleMDX
                        }}
                      >
                        <MDXRenderer>
                          {prettyTitle.childMdx.body}
                        </MDXRenderer>
                      </MDXProvider>
                    )
                  )}
                </SectionTitle>
              </Fade>
            </Content>
          </Row>
          {clients && (
            <Row>
              <Content>
                <Fade cascade>
                  <Clients>
                    <Row>
                      {clients.sort((a, b) => (a.node.sort > b.node.sort) ? 1 : ((b.node.sort > a.node.sort) ? -1 : 0)).map((edge, i) => {
                        const {
                          id,
                          // name,
                          logo,
                          showLogo
                          // sort
                        } = edge.node;
                        return (
                          logo && showLogo && (
                            <Client key={id} src={logo.file.url} />
                          )
                        );
                      })}
                    </Row>
                  </Clients>
                  </Fade>
              </Content>
            </Row>
          )}
          {clients && (
            <Row>
              <Content>
                <Button onClick={() => this.handleClick()}>
                  Full client list
                </Button>
                <ClientList open={showList}>
                  {clients.sort((a, b) => (a.node.name > b.node.name) ? 1 : ((b.node.name > a.node.name) ? -1 : 0)).map((edge, i) => {
                    const {
                      id,
                      name
                    } = edge.node;
                    return (
                      <ClientListItem key={`client-li-${id}`} open={showList}>
                        <Fade bottom distance={`0.5em`} when={showList} delay={30 * i}>
                          {name}
                        </Fade>
                      </ClientListItem>
                    );
                  })}
                </ClientList>
              </Content>
            </Row>
          )}
        </Container>
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" />
              <use xlinkHref="#gentle-wave" x="48" y="5" />
              <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
