import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const Img = ({
  objectFit = `cover`,
  objectPosition = `50% 50%`,
  imgStyle,
  ...props
}) => {
  // const { imgStyle, objectFit, objectPosition } = this.props;
  const polyfillHack = `"object-fit: ${imgStyle && (imgStyle.objectFit ? imgStyle.objectFit : objectFit)}; object-position: ${imgStyle && (imgStyle.objectPosition ? imgStyle.objectPosition : objectPosition)}"`;
  return (
    <Image
      {...props}
      imgStyle={{
        ...imgStyle,
        objectFit: imgStyle && (
          imgStyle.objectFit ? imgStyle.objectFit : objectFit
        ),
        objectPosition: imgStyle && (
          imgStyle.objectPosition ? imgStyle.objectPosition : objectPosition
        ),
        fontFamily: polyfillHack
      }}
    />
  );
};

export default Img;

Img.propTypes = {
  objectFit: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imgStyle: PropTypes.object,
  objectPosition: PropTypes.string
};
Img.defaultProps = {
  objectFit: `cover`,
  imgStyle: {},
  objectPosition: `50% 50%`
};
