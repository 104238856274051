import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// import ReactPlayer from 'react-player';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import { ReactComponent as PAWA } from 'assets/PAWA-logo.svg';
import {
  LineSeparator
} from 'components/Elements';

import {
  SectionLabel
} from 'components/Text';

const Section = styled.section`
  ${tw`
    bg-gray-900
    flex
    min-h-screen
    flex-wrap
    pt-3
    md:pt-4
    lg:pt-6
    xl:pt-8
    px-3
    md:px-1/12
    lg:px-1/14
    xl:px-1/48
    relative
  `};
`;

const SectionDivider = styled.aside`
  ${tw`
    block
    absolute
    pointer-events-none
    bg-gray-900
    left-0
    right-0
    h-12
    border-0
    border-solid
    border-gold-300
    bottom--4
  `};
  border-top-width: 6rem;
  &:before,
  &:after {
    ${tw`
      absolute
      bg-gold-300
      right--0.5
      left--0.5
      h-6
    `};
    box-sizing: border-box;
    content: " ";
  }

  &:before {
    ${tw`
    bottom-0
    z-2
    `};
    border-radius: 0 90px 0 0;
  }

  &:after {
    ${tw`
      bg-gray-900
    `};
    bottom: 6rem;
    border-radius: 0 0 0 90px;
  }
`;
// .ss-style-invertedrounded {
//   margin-bottom: 90px;
//   padding: 13em 10 % 10em;
//   border-radius: 0 0 0 90px;
// }


const ColText = styled.div`
  ${tw`
    flex
    lg:h-screen
    py-3
    md:py-4
    lg:py-5
    xl:py-6
    xl:pr-1/12
    lg:w-1/2
    xl:mx-1/12
    flex-2
    w-full
  `};
`;
const Content = styled.div`
  ${tw`
    xl:mx-1/12
    pb-1
    md:pb-2
    lg:pb-3
    w-full
  `};
`;
const Title = styled.h1`
  ${tw`
    tracking-tight
    font-bold
    lg:text-superhero-2xl
    xl:text-superhero-3xl
    pt-1
    relative
  `};
`;
const Subtitle = styled.div`
  ${tw`
    font-light
    tracking-wider
    text-md
    md:text-lg
    lg:text-lg
    leading-2
  `};
`;
const X = styled.span`
  ${tw`
    inline-block
    relative
    lg:h-6
    lg:w-6
    xl:h-7
    xl:w-7
  `};
  &:before, &:after{
    ${tw`
      absolute
      bg-white
      transition-all
      transition-200
      transition-ease
      lg:h-6
      xl:h-7
    `};
    width: 4px;
    content:'';
    top: 4px;
  }
  &:before{
    ${tw`
      lg:left-3
      xl:left-3.5
    `};
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
  &:after{
    ${tw`
      lg:left-3
      xl:left-3.5
    `};
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
`;
const SectionTitle = styled.h1`
  ${tw`
    inline-block
    text-brand-primary
    tracking-tighter
    h-5
    lg:h-auto
    font-bold
    text-6xl
    lg:text-superhero-2xl
    mt--1
    relative
  `};
  hr {
    ${tw`
      relative
      top-0
      lg:top-2
      xl:top-2.5
    `};
  }
  span {
    ${tw`
      inline-block
      mr-2px
      ml--1px
      lg:mr-6px
      lg:ml--2px
    `};
  }
  em {
    ${tw`
      inline-block
      not-italic
      lg:mr-3px
    `};
  }
  svg {
    ${tw`
      relative
      top-2
      h-2.5
      ml-4px
      lg:ml-1
      lg:mr--2px
      lg:h-8
      inline-block
      fill-white
    `};
    polygon, rect, path {
      ${tw`
        
      `};
    }
  }
`;
const Service = styled.div`
  ${tw`
    md:w-1/2
    xl:w-1/3
    pb-3
    md:pb-4
    lg:pb-6
    xl:pb-8
  `};
  color: rgba(255,255,255,0.9);
  &:nth-child(2n+1) {
    ${tw`
      md:pr-1/48
    `};
  }
  &:nth-child(2n+2) {
    ${tw`
      md:pl-1/48
    `};
  }
  &:nth-child(3n+1) {
    ${tw`
      xl:pl-0
      xl:pr-1/48
    `};
  }
  &:nth-child(3n+2) {
    ${tw`
      xl:px-1/48
    `};
  }
  &:nth-child(3n+3) {
    ${tw`
      xl:pr-0
      xl:pl-1/48
    `};
  }
  span {
    ${tw`
      text-sm
      lg:text-md
      block
      font-light
    `};
    color: rgba(255,255,255,0.4);
  }
  h1 {
    ${tw`
      text-white
      text-2xl
      lg:text-3xl
      xl:text-4xl
      font-semibold
      tracking-tight
      mb-3
    `};
  }
  p {
    ${tw`
      text-xs
      md:text-sm
      lg:pr-4
      font-light
      tracking-widest
      leading-1.9
      md:min-h-6.5
    `};
  }
  ul {
    ${tw`
      my-2
      lg:my-3
    `};
    li {
      ${tw`
        font-light
        tracking-widest
        lg:text-xl
        mb-0
        lg:mb-0.5
      `};
    }
  }
`;
const wavesMotionTwo = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;
const Waves = styled.div`
  ${tw`
    absolute
    left-0
    right--2px
    w-full
    top--4px
    lg:top--0.5
    overflow-hidden
    h-2
    lg:h-3
  `};
  transform: rotate(180deg);
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  svg {
    ${tw`
      min-h-12px
      max-h-24px
      lg:min-h-20px
      lg:max-h-40px
      relative
      w-full
    `};
    margin-bottom:-7px; /*Fix for safari gap*/
    g {
      > use {
        animation: ${wavesMotionTwo} 1025s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      > use:nth-child(1) {
        ${tw`
          opacity-50
          fill-gray-800
        `};
        animation-delay: -2s;
        animation-duration: 7s;
      }
      > use:nth-child(2) {
        ${tw`
          opacity-40
          fill-gray-800
        `};
        animation-delay: -3s;
        animation-duration: 10s;
      }
      > use:nth-child(3) {
        ${tw`
          opacity-20
          fill-gray-800
        `};
        animation-delay: -4s;
        animation-duration: 13s;
      }
      > use:nth-child(4) {
        ${tw`
          opacity-100
          fill-gray-800
        `};
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      entries: 4,
      modal: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleClick () {
    const { entries } = this.state;
    this.setState({
      entries: entries + 2
    })
  }

  handleModal (id) {
    this.setState({
      modal: id != this.state.modal ? id : null
    })
  }

  render () {
    const {
      hero,
      ordinal,
      services
    } = this.props;
    const {
      heading,
      content
    } = hero;
    return (
      <Section id={`services`}>
        <Container fluid>
          <Row>
            <Content>
              <Fade top distance={`1em`}>
                <SectionLabel label={`What can we do for you?`} ordinal={ordinal} color={`white`} width={`220px`} />
              </Fade>
              <Fade bottom distance={`1em`}>
                <SectionTitle>
                  <em>S</em>u<span>p</span>er<PAWA />s
                  <LineSeparator color={`white`} />
                </SectionTitle>
              </Fade>
            </Content>
          </Row>
          <Row>
            <Content>
              <Row>
                {services.map((edge, i) => {
                  const service = edge.node;
                  return (
                    <Service key={`service-${i}`}>
                      <Fade bottom distance={`1em`} delay={220 * i}>
                        <span>0{i + 1}</span>
                        <h1>{service.title}</h1>
                        {service.summary && (
                          service.summary.childMdx && (
                            <MDXRenderer>
                              {service.summary.childMdx.body}
                            </MDXRenderer>
                            )
                        )}
                        {service.serviceAreas && (
                          <ul>
                            {service.serviceAreas.map((area)=> {
                              return (
                                <li key={`serviceArea-${area.id}`}>{area.title}</li>
                              )
                            })}
                        </ul>
                        )}
                        <LineSeparator color={`white`} />
                      </Fade>
                    </Service>
                  )
                })}
              </Row>
            </Content>
          </Row>
        </Container>
        {/* 
        <SectionDivider />
         */}
        <Waves>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g>
              <use xlinkHref="#gentle-wave" x="48" y="0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" />
              <use xlinkHref="#gentle-wave" x="48" y="5" />
              <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
          </svg>
        </Waves>
      </Section>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
